<template>
  <div id="order-success">
    <div class="box-order-success">
      <div class="galochka">
        <svg>
          <use :xlink:href="'../../img/icons/socialSprite.svg#check'"></use>
        </svg>
      </div>
      <div class="text">
        Спасибо за заказ, мы свяжемся с вами в ближайшее время
      </div>
      <button class="continue-buy" @click="$router.push('/catalog/')">
        Продолжить покупки
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderSuccess",
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles"
#order-success
  height: 100%
  display: flex
  padding: 0 15px

  .box-order-success
    text-align: center
    margin: auto
    width: 770px
    height: fit-content
    color: $main-color

    .text
      font-family: EuropeExt, sans-serif
      font-size: 36px
      line-height: 125%
      margin-top: 35px

    .continue-buy
      font-weight: 400
      font-size: $main-font-size
      line-height: 19px
      text-align: center
      color: #fff !important
      padding: 12px 32px
      text-transform: none
      width: 310px
      height: 48px
      margin-top: 35px
      letter-spacing: 0.8px
      &:hover
        background-color: $main-color
        color: #e8ebec !important
        border: 0

@media (max-width: 1439px) and (min-width: 992px)
  #order-success
    .box-order-success
      .text
        font-size: 24px
        margin-top: 24px

      .continue-buy
        width: 288px
        height: 54px
        margin-top: 23px

      .galochka
        svg
          width: 66px
          height: 50px

@media (max-width: 460px)
  #order-success
    .box-order-success
      margin: auto

      .text
        font-size: 18px
        margin-top: 35px

      .continue-buy
        padding: 12px 32px 14px
        width: 100%
        height: 54px

      .galochka
        svg
          height: 55px
          width: 55px
</style>
