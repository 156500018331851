<template>
  <div class="modal-overlay">
    <div class="modal_order col-md-4">
      <div class="title d-flex flex-nowrap w-100">
        <h5 class="p-4 padding-right0 mb-0 text-start">
          Введите данные чтобы оператор связался с вами
        </h5>
        <a class="close" type="button" @click="$emit('close-modal')">
          <i class="ion ion-close"></i>
        </a>
      </div>
      <form @submit.prevent="submitForm">
        <div class="w-100" v-if="!isChat">
          <label for="fio">Ф.И.О. <span>*</span></label>
          <label class="error" v-if="msg.fio">{{ msg.fio }}</label>
          <input
            type="text"
            minlength="3"
            placeholder="Маск Илона Игоревна"
            v-model.trim="fio"
            class="input-modal"
            :required="!isChat"
          />
        </div>

        <label for="phone">Телефон <span v-if="!isChat">*</span></label>
        <label class="error" v-if="msg.phone">{{ msg.phone }}</label>
        <input
          placeholder="+7 (123) 456-78-99"
          class="input-modal"
          :value="phone"
          :required="!isChat"
          type="text"
          minlength="11"
          v-imask="mask"
          @accept="setPhone"
        />
        <label for="email">E-mail <span>*</span></label>
        <label class="error" v-if="msg.email">{{ msg.email }}</label>
        <input
          placeholder="elona@gmail.com"
          type="email"
          v-model="email"
          class="input-modal"
          required
        />
        <label>
          {{ isChat ? "Задайте вопрос" : "Комментарии" }}
          <span v-if="isChat">*</span>
        </label>
        <textarea
          v-model="comment"
          :placeholder="
            isChat ? 'Есть ли в наличии...' : 'Доставка нужна срочно...'
          "
          class="form-control mb-5"
          rows="5"
          :required="isChat"
        />
        <button
          type="submit"
          :key="btnDisable"
          :disabled="btnDisable"
          class="button-send-order"
        >
          Отправить
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { IMaskDirective } from "vue-imask";

export default {
  name: "OrderModel",
  props: {
    isChat: {
      type: Boolean,
      default: () => false,
    },
  },
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      email: null,
      fio: null,
      comment: null,
      phone: null,
      mask: {
        mask: "{+7} 000-000-00-00",
        lazy: true,
      },
      form: true,
      msg: [],
      btnDisable: false,
    };
  },
  watch: {
    email(value) {
      this.email = value;
      //this.validateEmail(value);
    },
    fio(value) {
      this.fio = value;
      // this.validateFio(value);
    },
    phone(value) {
      this.phone = value;
      //this.validatePhone(value);
    },
    form() {
      this.validateForm();
    },
    "$store.state.closeModal": {
      handler() {
        this.fio = null;
        this.email = null;
        this.phone = null;
        this.comment = null;
      },
    },
  },
  methods: {
    setPhone(e) {
      const maskRef = e.detail;
      this.phone = maskRef.unmaskedValue;
    },
    validateEmail(value) {
      // if (!value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      if (!value) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Некорректный e-mail адрес";
      }
    },
    validateFio(value) {
      if (!!value && value.length < 3) {
        this.msg["fio"] = "Минимальное кол-во символов 3";
      } else {
        this.msg["fio"] = "";
      }
    },
    validatePhone(value) {
      if (!!value && value.length < 12) {
        let difference = 12 - value.length;
        this.msg["phone"] =
          "Должно быть 12 символов! " + difference + " осталось";
      } else {
        this.msg["phone"] = "";
      }
    },
    validateForm() {
      return this.validateEmail && this.validateFio && this.validatePhone;
    },
    async submitForm() {
      this.btnDisable = true;
      if (this.isChat) {
        const body = {
          phone: this.phone,
          email: this.email,
          description: this.comment,
        };
        await this.$store.dispatch("sendMessage", body);
        this.btnDisable = false;
        return;
      }
      const cart = JSON.parse(localStorage.getItem("cart"));
      if (!this.msg.length && !!cart.length) {
        const body = {
          fio: this.fio,
          phone: this.phone,
          email: this.email,
          comment: this.comment,
          cart,
        };
        await this.$store.dispatch("sendDataCart", body);
        this.btnDisable = false;
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles.sass"
.modal-overlay
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  justify-content: center
  background-color: rgba(0, 0, 0, 0.5)
  z-index: 9999

  .modal_order
    text-align: center
    background-color: #ffffff
    height: 523px
    width: 376px
    margin: auto 0
    border-radius: 5px
    z-index: 9999

    h5
      font-family: "Inter" !important
      font-weight: 700
      line-height: 120%
      font-size: 20px

    form
      font-family: "Inter" !important
      height: calc(100% - 94px)
      display: flex
      flex-direction: column
      padding: 0 15px
      text-align: start

      .input-modal
        width: 100%
        height: 32px
        min-height: 32px
        margin-bottom: 12px
        font-size: 14px
        padding-left: 6px
        color: $main-color
        border: solid 1px $default-color
        border-radius: 3px
        &:focus
          border-color: $main-color
          outline: solid 1px $main-color !important
          box-shadow: none

      label
        float: left
        font-size: 10px
        margin-bottom: 0

      .button-send-order
        width: 100%
        height: 33px
        color: #fff !important
        text-align: center
        padding-bottom: 25px
        font-size: 16px
        font-weight: 500
        line-height: 8px
        &:hover
          background-color: $main-color
        &:disabled
          background: $default-color

      textarea
        height: 100%
        width: 312px
        margin: 0 auto
        resize: none
        color: $main-color
        border-radius: 2px
        border-color: $default-color

        &:focus
          border-color: $main-color
          outline: solid 1px $main-color !important
          box-shadow: none

      .error
        font-size: 10px
        color: red

@media (max-width: 1439px) and (min-width: 992px)
  .modal-overlay
    .modal_order
      h5
        font-size: 18px

      form
        label
          font-size: 12px

@media (max-width: 460px)
  .modal-overlay
    .modal_order
      height: 100%
      width: 100%
      margin-top: 0
      border-radius: 0
      padding: 0

      h5
        margin-top: 15px
        font-size: 18px

      .title
        height: 88px

      form
        height: calc(100% - 88px)
        padding: 0 15px 15px
        display: flex
        flex-direction: column
        align-items: flex-start

        label
          padding-left: 0

        .input-modal
          width: 100%
          min-height: 41px

        textarea
          width: 100%
          height: 100%
          margin-top: 0
          min-height: 200px

        .button-send-order
          width: 100%
          margin-top: auto
</style>
