import { createApp } from "vue";
import { createStore } from "vuex";
import appService from "@/services/apiServises";
import router from "./../router";
import App from "@/App";

createApp(App).use(router);

function updateLocalStorage(cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
}

export default createStore({
  state: {
    cart: [],
    product: {},
    products: {},
    querySearch: "",
    categories: [],
    blog: {},
    blogs: [],
    comments: [],
    recommendProduct: [],
    catalog: [],
    closeModal: null,
    serviceTypes: [],
    serviceList: [],
    currentCategoryId: null,
  },
  getters: {
    querySearch: (state) => {
      return state.querySearch;
    },
    productQuantity: (state) => (product) => {
      const item = state.cart.find((i) => i.id === product.id);
      if (item) {
        return item.quantity;
      } else {
        return null;
      }
    },
    cartItems: (state) => {
      return state.cart;
    },
    totalCostOrder: (state, getters) => {
      return getters.cartItems.reduce(
        (total, product) =>
          total +
          (product.price_with_sale > 0
            ? product.price_with_sale
            : product.price) *
            product.quantity,
        0
      );
    },
  },
  mutations: {
    addToCart(state, product) {
      let item = state.cart.find((i) => i.id === product.id);

      if (item) {
        item.quantity++;
      } else {
        state.cart.push({ ...product, quantity: 1 });
      }

      updateLocalStorage(state.cart);
    },

    removeItemQutCart(state, product) {
      let item = state.cart.find((i) => i.id === product.id);

      if (item.quantity > 1) {
        item.quantity--;
      } else {
        state.cart = state.cart.filter((i) => i.id !== product.id);
      }
      updateLocalStorage(state.cart);
    },

    removeItemCart(state, product) {
      const i = state.cart.map((item) => item.id).indexOf(product.id);
      state.cart.splice(i, 1);
      updateLocalStorage(state.cart);
    },

    updateCartLocalStorage(state) {
      const cart = localStorage.getItem("cart");

      if (cart) {
        state.cart = JSON.parse(cart);
      }
    },
    // TODO: make clear all cart
    clearCart() {
      localStorage.removeItem("cart");
    },
    setProduct(state, product) {
      state.product = product;
    },
    setProducts(state, data) {
      state.products = data;
    },
    saveQuerySearch(state, query) {
      state.querySearch = query;
    },
    setCategories(state, data) {
      state.categories = data;
    },
    setBlogs(state, data) {
      state.blogs = data;
    },
    setBlog(state, data) {
      state.blog = data;
    },
    setComments(state, data) {
      state.comments = data;
    },
    setRecommendProduct(state, products) {
      state.recommendProduct = products;
    },
    setCatalog(state, data) {
      state.catalog = data;
    },
    toggleModal(state, toggle) {
      state.closeModal = toggle;
    },
    setServices(state, categories) {
      state.serviceTypes = categories;
    },
    setServiceList(state, data) {
      state.serviceList = data;
    },
    currentCategory(state, id) {
      state.currentCategoryId = id;
    },
  },
  actions: {
    clearCart({ commit }) {
      commit("updateCartLocalStorage");
    },
    getProduct({ commit }, slug) {
      appService.getProduct(slug).then((response) => {
        if (response.status === 200) {
          commit("setProduct", response.data);
        }
      });
    },
    getProducts({ commit }, payload) {
      appService.getProducts(payload).then((response) => {
        if (response.status === 200) {
          commit("setProducts", response.data);
        }
      });
    },
    setQuerySearch({ commit }, query) {
      commit("saveQuerySearch", query);
    },
    getCategories({ commit }) {
      appService.getCategories().then((response) => {
        if (response.status === 200) {
          commit("setCategories", response.data);
        }
      });
    },
    getBlogs({ commit }) {
      appService.getBlogs().then((response) => {
        if (response.status === 200) {
          commit("setBlogs", response.data);
        }
      });
    },
    getBlog({ commit }, slug) {
      appService.getBlog(slug).then((response) => {
        if (response.status === 200) {
          commit("setBlog", response.data);
        }
      });
    },
    createComment({ commit }, comment) {
      appService.saveComment(comment).then((response) => {
        if (response.status === 201) {
          appService.getComments(comment.post).then((response) => {
            if (response.status === 200) {
              commit("setComments", response.data.results);
            }
          });
        }
      });
    },
    getComments({ commit }, blogId) {
      appService.getComments(blogId).then((response) => {
        if (response.status === 200) {
          commit("setComments", response.data.results);
        }
      });
    },
    getRecommendProduct({ commit }) {
      appService.getRecommendProduct().then((response) => {
        if (response.status === 200) {
          commit("setRecommendProduct", response.data.results);
        }
      });
    },
    getCatalog({ commit }) {
      appService.getCatalog().then((response) => {
        if (response.status === 200) {
          commit("setCatalog", response.data);
        }
      });
    },
    sendMessage({ commit }, body) {
      return appService
        .sendMessage(body)
        .then((response) => {
          if (response.status === 201) {
            commit("toggleModal", false);
          }
        })
        .catch((error) => console.error(error));
    },
    sendDataCart({ commit }, body) {
      return appService
        .sendCart(body)
        .then((response) => {
          if (response.status === 201) {
            commit("toggleModal", false);
            localStorage.setItem("cart", JSON.stringify([]));
            commit("updateCartLocalStorage");
            router.push({ name: "OrderSuccess" });
          }
        })
        .catch(() => {
          router.push({ name: "404" });
        });
    },
    getServiceTypes({ commit }) {
      appService.getServices().then((response) => {
        if (response.status === 200) {
          commit("setServices", response.data.results);
        }
      });
    },
    getServiceList({ commit }, params) {
      appService.getServiceList(params).then((response) => {
        if (response.status === 200) {
          commit("setServiceList", response.data);
        }
      });
    },
    toggleModal({ commit }, params) {
      commit("toggleModal", params);
    },
    setCurrentCategory({ commit }, id) {
      commit("currentCategory", id);
    },
  },
  modules: {},
});
