import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home";
import About from "../components/About";
import Contacts from "../components/Contacts";
import Blogs from "../components/blog/Blogs";
import BlogSingle from "../components/blog/BlogSingle";
import Category from "@/components/categories/Category";
import NotFound from "@/components/NotFound";
import ProductDetail from "@/components/products/ProductDetail";
import Checkout from "@/components/Checkout";
import OrderSuccess from "@/components/OrderSuccess";
import ServiceDetail from "@/components/service/ServiceDetail";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/blog/",
    name: "Blogs",
    component: Blogs,
  },
  { path: "/blog/:slug", name: "Blog", component: BlogSingle },
  {
    path: "/catalog/",
    name: "Catalog",
    component: () =>
      import(/* webpackChunkName: "catalog" */ "../components/Catalog"),
  },
  {
    path: "/services/",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../components/Services"),
  },
  {
    path: "/services/:slug",
    name: "ServiceDetail",
    component: ServiceDetail,
  },
  {
    path: "/about-us/",
    name: "About",
    component: About,
  },
  {
    path: "/contacts/",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/checkout/",
    name: "Checkout",
    component: Checkout,
  },
  { path: "/catalog/:category/", name: "Category", component: Category },
  {
    path: "/catalog/product/:slug/",
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound,
  },
  {
    path: "/order-success/",
    name: "OrderSuccess",
    component: OrderSuccess,
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // page scroll to top for all route navigations
    window.scrollTo(0, 0);
  },
});
export default router;
