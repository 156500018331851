<template>
  <div class="box-product">
    <a
      :href="`${
        product.type_entity === 'product'
          ? `/catalog/product/${product.slug}`
          : `/services/${product.slug}`
      }`"
      @click="setService(product)"
      :class="{ no_image: !product.image }"
    >
      <div class="cart-thumb" v-lazy-load="'image'">
        <img
          class="cart-thumb__img"
          :data-url="product.image"
          src="../../assets/images/product/no_image.webp"
          alt=""
        />
      </div>
    </a>
    <div>
      <div class="code">Код: {{ product.code }}</div>
      <a @click="removeItemCart" class="remove"
        ><i class="ion ion-close"></i
      ></a>
      <div class="minusplusnumber">
        <a @click="removeItemQutCart">
          <div class="mpbtn minus">-</div>
        </a>
        <div id="field_container">
          <div>{{ product_quantity }}</div>
        </div>
        <a @click="addToCart">
          <div class="mpbtn plus" v-if="product_quantity <= 99">+</div>
        </a>
        <div class="mpbtn plus" v-if="product_quantity > 99"></div>
      </div>
      <a
        :href="`${
          product.type_entity === 'product'
            ? `/catalog/product/${product.slug}`
            : `/services/${product.slug}`
        }`"
        @click="setService(product)"
      >
        <div class="name">{{ product.name }}</div>
      </a>
      <span class="amount" v-if="product.price_with_sale > 0">
        {{ product.price_with_sale.toLocaleString() }} &#x20bd;
      </span>
      <span class="amount" v-else>
        {{ product.price.toLocaleString() }} &#x20bd;
      </span>
      <span class="total-amount"
        >{{ total_cost_item.toLocaleString() }} &#x20bd;</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CartItemsCheckout",
  props: ["product"],
  computed: {
    total_cost_item() {
      if (this.product.price_with_sale > 0) {
        return this.product.price_with_sale * this.product.quantity;
      } else {
        return this.product.price * this.product.quantity;
      }
    },
    product_quantity() {
      return this.product.quantity;
    },
  },
  methods: {
    setService(service) {
      if (service.type_entity === "service") {
        localStorage.setItem("serviceItem", JSON.stringify(service));
      }
    },
    removeItemQutCart() {
      this.$store.commit("removeItemQutCart", this.product);
    },
    removeItemCart() {
      this.$store.commit("removeItemCart", this.product);
    },
    addToCart() {
      this.$store.commit("addToCart", this.product);
    },
  },
};
</script>

<style scoped lang="sass">
.box-product
  padding: 20px 0
  position: relative
  border-top: 1px solid #BDBDBD
  line-height: 120%
  display: flex
  &:first-child
    margin-top: 30px
  &:last-child
    border-bottom: 1px solid #BDBDBD
  .cart-thumb
    width: 102px
    height: 90px
    margin-right: 15px

    &__img
      height: 100% !important
      width: 100%

  .amount
    font-size: 14px

  .vendor-code,
  .code
    font-weight: 400
    font-size: 14px
    line-height: 120%
    color: #3D3D3D
    opacity: 0.5
    margin-bottom: 15px

  .remove
    display: block
    position: absolute
    top: 26px
    right: 0

  .minusplusnumber
    position: relative
    bottom: 40px
    left: 330px

  .name
    color: #000
    position: relative
    bottom: 20px
    overflow: hidden
    text-overflow: ellipsis
    display: -webkit-box
    -webkit-line-clamp: 2
    -webkit-box-orient: vertical

  .total-amount
    position: absolute
    top: 25px
    right: 40px
    font-weight: 700

@media (max-width: 1439px) and (min-width: 992px)
  .box-product
    margin: 0

    .cart-thumb
      width: 68px
      height: 60px
      &__img
        margin-right: 10px
        margin-bottom: 50px

    .vendor-code,
    .code
      font-size: 12px

    .remove
      bottom: 20px

    .minusplusnumber
      bottom: 34px
      left: 190px

    .name
      font-size: 14px

    .amount
      font-size: 12px

    .total-amount
      right: 33px
      font-size: 14px

@media (max-width: 460px)
  .box-product
    width: 100%
    &:last-child
      border-bottom: none
      margin-bottom: 20px

    .cart-thumb
      width: 68px
      height: 60px

    .vendor-code,
    .code
      width: 100%
      font-size: 12px

    .remove
      font-size: 12px
      bottom: 20px
      right: 10px

    .minusplusnumber
      top: 70px
      left: 0

    .name
      height: 40px
      bottom: 30px
      font-size: 14px
      margin-bottom: 40px

    .amount
      font-size: 12px
      position: absolute
      bottom: 65px

    .total-amount
      right: 30px
      top: 130px
      font-size: 14px
</style>
