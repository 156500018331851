<template>
  <div id="about">
    <div class="container">
      <div class="post-heading-center">
        <div class="logo">
          <img src="../assets/images/icon/big_logo_dark.svg" alt="logo" />
        </div>
      </div>
      <div class="box-history">
        <div class="about-history-img"></div>
        <div class="about-history-text">
          <div class="handler">Основная деятельность компании</div>
          <p class="history-desc">
            Компания Порша Ру специализируется на обслуживании и ремонте Порше.
            А также профессионально подбирает и продает запчасти на эту марку.
          </p>
          <p class="history-desc">
            Наша внутренняя политика основана на открытости по отношению к
            клиентам. Мы не навязываем услуги, а также показываем все работы от
            и до. Объясняем клиенту простым языком все нюансы ремонта,
            консультируем, подбираем оптимальное и разумное обслуживание для
            каждого. Мы заинтересованы в том, чтобы владелец имел хороший и
            исправный автомобиль, при этом не тратя лишние деньги и время.
          </p>
          <p class="history-desc">
            Также, при работе с автомобилем мы смотрим на будущее, чтобы решить
            не только проблему здесь и сейчас, но и максимально предотвратить
            новые.
          </p>
        </div>
      </div>
      <div v-lazy-load="'image'" class="box-activity">
        <div class="activity-text">
          <div class="handler">Наша цель</div>
          <p class="activity-desc">
            Сделать жизнь владельцев Порше более счастливой и комфортной,
            предоставляя им надежный ремонт и обслуживание, тем самым освободив
            их личное время для семьи, работы и творчества, что, в свою очередь,
            принесёт пользу обществу.
          </p>
          <p class="activity-desc">
            Наши клиенты отмечают, что с начала нашего сотрудничества они не
            беспокоятся об обслуживании и ремонте своего Порше, потому есть мы!
          </p>
        </div>
        <img
          class="activity-img"
          :data-url="'/img/our_goal.webp'"
          alt="our_goal"
        />
      </div>
      <div class="text-center">
        <button class="button-conf" @click="getFile('politica.pdf')">
          Политика конфиденциальности
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  methods: {
    getFile(file) {
      window.open("/" + file, "_blank");
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles"
#about
  margin-top: 210px
  color: $main-color

  .container
    .box-history,
    .box-activity
      display: flex
      width: 100%
      margin-bottom: 60px
      justify-content: center

      .about-history-img
        width: 535px
        min-height: 305px
        margin-right: 25px
        background-image: url("../assets/images/bg/about_history_bg.webp")
        background-size: cover

      .about-history-text,
      .activity-text
        width: 760px

        .handler
          font-size: 30px
          font-weight: 500
          line-height: 33px
          margin-bottom: 25px
          letter-spacing: 1.1px

        .history-desc,
        .activity-desc
          font-weight: 400
          font-size: 18px
          line-height: 23px
          letter-spacing: .6px

          &:last-child
            margin: 0

    .box-activity
      margin-bottom: 0

      .activity-text
        width: 650px
        margin-right: 25px

      .activity-img
        width: 648px
        height: 415px

    .button-conf
      text-transform: none
      background-color: #fff
      height: 64px
      text-align: center
      border-radius: 0
      font-size: 20px
      font-weight: 500
      margin-top: 50px
      margin-bottom: 120px
      &:hover
        background-color: #e8ebec
        color: $main-color !important

    .post-heading-center
      margin-bottom: 90px

      .logo
        height: 75px
        text-align: center
        margin: 0 auto

@media (max-width: 460px)
  #about
    margin-top: 80px

    .container
      .post-heading-center
        margin-bottom: 30px

        .logo
          width: 100%
          height: 37px

      .box-history,
      .box-activity
        height: fit-content
        display: flex
        flex-direction: column
        margin-bottom: 60px

        .about-history-img,
        .activity-img
          width: inherit
          height: 260px
          margin: 0 auto 30px

        .about-history-text,
        .activity-text
          width: inherit
          height: auto

          .handler
            font-size: 22px
            line-height: 24px
            margin-bottom: 15px

          .history-desc,
          .activity-desc
            font-weight: 500
            font-size: 14px
            line-height: 19px

      .box-activity
        margin-bottom: 0

        .activity-text
          order: 1
          margin-right: 0

      .button-conf
        height: 54px
        font-size: $main-font-size
        line-height: 19px
        margin-top: 40px
        margin-bottom: 60px
        &:hover
          background-color: #fff

@media (max-width: 1439px) and (min-width: 992px)
  #about
    margin-top: 140px

    .container
      .box-history,
      .box-activity
        .about-history-img
          width: 389px
          margin-right: 20px

        .about-history-text,
        .activity-text
          width: 550px

          .handler
            font-size: 24px
            line-height: 26px

          .history-desc,
          .activity-desc
            font-size: 14px
            line-height: 150%

        .activity-text
          width: 512px
          margin-right: 55px

        .activity-img
          width: 389px
          height: 305px

      .button-conf
        height: 54px
        font-size: $main-font-size
        margin-top: 30px
        margin-bottom: 90px

      .post-heading-center
        margin-bottom: 60px

        .logo
          height: 50px
          width: 400px

@media (min-width: 3326px)
  #about
    height: calc(100% - 210px)
    display: flex
    flex-direction: column
    justify-content: center
</style>
