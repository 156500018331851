<template>
  <div
    class="nav-social-icon"
    :class="{ dark: dark === 'dark', light: light === 'light' }"
  >
    <a :href="soc.href" v-for="soc in socialData" :key="soc.id">
      <svg :id="soc.id">
        <use :xlink:href="soc.icon"></use>
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: "SocialLinks",
  props: {
    dark: {
      type: String,
      default: "",
    },
    light: {
      type: String,
      default: "",
    },
  },
  computed: {
    socialData() {
      return [
        {
          id: "youtube",
          icon: "../../img/icons/socialSprite.svg#youtube",
          href: "https://www.youtube.com/channel/UCz4FGa_nWDaeLaFgdpNz7nQ",
        },
        {
          id: "whatsapp",
          icon: "../../img/icons/socialSprite.svg#whatsapp",
          href: "https://wa.me/79254227353",
        },
        {
          id: "mail",
          icon: "../../img/icons/socialSprite.svg#mail",
          href: "mailto:5050038@bk.ru",
        },
        {
          id: "drive2",
          icon: "../../img/icons/socialSprite.svg#drive2",
          href: "https://www.drive2.ru/o/porsharu",
        },
        {
          id: "vk",
          icon: "../../img/icons/socialSprite.svg#vk",
          href: "https://vk.com/porsha_ru",
        },
      ];
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles"
.nav-social-icon
  width: fit-content
  margin: 0 auto 35px

  svg
    height: 64px
    width: 100px
    &#vk
      fill: none
    &:hover
      fill: #5f6c79

  &.dark
    svg
      &#vk
        stroke: $main-color

      &#drive2
        fill: #fff
        stroke: $main-color

      &:hover
        &#vk,
        &#drive2
          stroke: #5f6c79

  &.light
    svg
      fill: #fff
      &#vk
        stroke: #fff

      &#drive2
        fill: $main-color
        stroke: #fff

      &:hover
        fill: #5f6c79
        &#vk,
        &#drive2
          stroke: #5f6c79

@media (max-width: 1440px) and (min-width: 992px)
  .nav-social-icon
    margin-bottom: 20px

    &.dark
      svg
        height: 52px
        width: 90px

@media (max-width: 460px)
  .nav-social-icon
    margin-bottom: 30px

    &.dark
      svg
        height: 52px
        width: 60px
</style>
