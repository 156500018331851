<template>
  <footer
    v-lazy-load="'component'"
    id="footer"
    class="flex-center"
    :class="{ 'mob-visible': showFooter }"
  >
    <div v-if="load" class="container">
      <div v-lazy-load="'image'" class="flex-center flex-wrap">
        <img
          class="logo"
          :data-url="'/img/logo_footer.svg'"
          alt="footer_logo"
        />
        <SocialLinks class="nav-social" light="light" />
      </div>
    </div>
  </footer>
</template>

<script>
import SocialLinks from "../components/SocialLinks";
import { ref } from "vue";

export default {
  name: "AppFooter",
  components: { SocialLinks },
  setup() {
    const load = ref(false);
    return { load };
  },
  computed: {
    showFooter() {
      return this.$route.path === "/contacts/" || this.$route.name === "404";
    },
  },
};
</script>

<style lang="sass">
@import "src/assets/sass/styles"

#footer
  color: #a9a9a9
  height: 136px
  background-color: $main-color
  width: 100%

  .logo
    height: 15px
    width: 200px

  .nav-social
    display: flex
    justify-content: space-around
    align-items: center
    margin: 0 0 0 30px
    a
      margin-left: 15px

      svg
        height: 37px
        width: 37px
@media (max-width: 460px)
  #footer
    &.mob-visible
      display: none

    .nav-social
      width: 100%
      margin: 15px 0 0 0
</style>
