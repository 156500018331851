<template>
  <header id="header" class="header-transparent">
    <nav id="navigation" class="affix">
      <div class="navbar">
        <div class="container-navbar">
          <div class="navbar-wrap">
            <div class="navbar-logo">
              <div class="navbar-logo-wrap">
                <router-link to="/">
                  <img
                    src="../assets/images/logo/logo.svg"
                    alt="Logo"
                    class="logo-prima"
                  />
                </router-link>
              </div>
            </div>
            <div class="navbar-menu">
              <ul class="nav">
                <li
                  v-for="linkM in menuLink"
                  :key="linkM.id"
                  class="menu-item"
                  :class="{
                    'current-menu-item':
                      $route.path.includes(linkM.route.toLowerCase()) ||
                      $route.name === linkM.route,
                  }"
                >
                  <router-link :to="`${linkM.link}`">
                    {{ linkM.name }}
                  </router-link>
                </li>
              </ul>
              <div class="affa-form-search">
                <input
                  v-model="querySearch"
                  type="text"
                  name="search"
                  id="search"
                  placeholder="Название, код"
                  @keydown="search($event)"
                />
                <input type="submit" @click="search($event)" value="search" />
              </div>

              <div class="navbar-secondary">
                <div class="navbar-btn">
                  <a class="btn-cart" @click="locationToCart">
                    <svg>
                      <use
                        :xlink:href="'../../img/icons/socialSprite.svg#chart'"
                      ></use>
                    </svg>
                    <span class="badge-discount" v-if="products_qua.length > 0">
                      {{
                        products_qua.reduce(
                          (count, prod) => (count += prod.quantity),
                          0
                        )
                      }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div
      v-show="$route.path === '/'"
      class="header-content-startup"
      data-parallax="scroll"
      data-speed="0.2"
      data-natural-width="1440"
      data-natural-height="1080"
    >
      <div class="header-content-shadow"></div>
      <div class="header-content-overlay">
        <div
          class="header-content-txt animation animated animation-fade-in"
          data-animation="animation-fade-in"
          data-delay="300"
        >
          <h1>
            Сервис<br class="mobile-visible" />
            и автозапчасти <br />Porsche в Москве
          </h1>
          <p>
            Специализированный сервис по ремонту и качественному обслуживанию
            марки Порше
          </p>
        </div>
        <span class="badge-chart flex-center" @click="toggleChat(true)">
          <router-link to="/">
            <svg>
              <use :xlink:href="'../../img/icons/socialSprite.svg#chat'"></use>
            </svg>
          </router-link>
        </span>
      </div>
      <!--      <vue-carousel :data="['Slide 1', 'Slide 2', 'Slide 3']"></vue-carousel>-->
    </div>

    <div class="header-content-menu">
      <button
        type="button"
        class="navbar-btn-toggle flex-center"
        @click="openBurger"
      >
        <i class="ion ion-navicon" style="color: white"></i>
      </button>
      <div class="menu-logo">
        <router-link to="/">
          <img src="../assets/images/logo/logo.svg" alt="Logo" />
        </router-link>
      </div>
      <div class="cart-icon">
        <a class="btn-cart" @click="locationToCart">
          <img src="../assets/images/icon/cart_mobile.svg" alt="" />
          <span class="badge-discount" v-if="products_qua.length > 0">
            {{
              products_qua.reduce((count, prod) => (count += prod.quantity), 0)
            }}
          </span>
        </a>
      </div>
    </div>
    <OrderModel
      :isChat="true"
      v-show="showModal"
      @close-modal="toggleChat(false)"
    />
  </header>
</template>

<script>
import OrderModel from "@/components/OrderModel";
export default {
  name: "AppHeader",
  props: ["openBurger", "cartOpen"],
  components: { OrderModel },
  data() {
    return {
      querySearch: "",
      showModal: false,
      products_qua: [],
      menuLink: [
        { id: 1, link: "/", route: "Home", name: "Главная" },
        { id: 2, link: "/catalog/", route: "Catalog", name: "Каталог" },
        { id: 3, link: "/services/", route: "Services", name: "Сервис" },
        { id: 4, link: "/blog/", route: "Blog", name: "Блог" },
        { id: 5, link: "/about-us/", route: "About", name: "О компании" },
        { id: 6, link: "/contacts/", route: "Contacts", name: "Контакты" },
      ],
    };
  },
  methods: {
    search(e) {
      if ((e.type === "keydown" && e.keyCode === 13) || e.type === "click") {
        this.$store.dispatch("setQuerySearch", this.querySearch);
        if (this.$route.path !== "/category/products/") {
          this.$router.push({
            name: "Category",
            params: {
              category: "products",
            },
          });
        }
      }
    },
    locationToCart() {
      if (this.$route.name === "Checkout") location.reload();
      else this.cartOpen();
    },
    toggleChat(isOpen) {
      this.$store.dispatch("toggleModal", isOpen);
    },
  },
  created() {
    this.products_qua = this.$store.getters.cartItems;
  },
  watch: {
    "$store.state.closeModal": {
      handler(newVal) {
        this.showModal = newVal;
      },
    },
    "$store.state.cart": {
      handler(val) {
        this.products_qua = val;
      },
      deep: true,
    },
    "$store.state.querySearch": {
      handler(val) {
        if (!val.length) this.querySearch = "";
      },
    },
  },
};
</script>
<style scoped lang="sass">
@import "src/assets/sass/styles"
#header
  .header-content-overlay
    width: 100vw
    height: 100vh
    text-align: center
    min-height: 700px
    background-image: url("../assets/images/slider/Porsche-Boxster-E-3 1.webp")
    background-repeat: no-repeat
    background-size: cover

    .header-content-txt
      h1
        font-size: 60px
        color: #fff
        line-height: 61px
        font-weight: 700
        text-transform: none
        margin: 0
        .mobile-visible
          display: none

      h1, p
        width: fit-content
        margin: auto
        box-shadow: 0 0 45px 90px rgba(0, 0, 0, 0.35)
        background-color: rgba(0, 0, 0, 0.35)

    .badge-chart
      position: absolute
      border-color: #fff
      border-radius: 50%
      background-color: #fff
      bottom: 55px
      width: 64px
      height: 64px
      right: 55px
      svg
        height: 27px
        width: 27px

  .header-content-shadow
    height: 100%
    width: 100%
    position: absolute
    background-image: url("../assets/images/bg/shadow.webp")
    background-repeat: repeat
    background-color: rgba(0, 0, 0, 0.2)

  #navigation.affix
    position: fixed
    font-family: "Inter", sans-serif
    right: 0
    left: 0
    z-index: 1100

    .navbar
      background-color: transparent
      box-shadow: none
      position: static
      color: #a9a9a9
      min-height: 0
      margin: 0
      border: 0
      border-radius: 0

      .container-navbar
        width: 1350px

        .navbar-wrap
          position: relative
          background-color: #fff
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.15)
          border-radius: 4px
          margin-top: 30px
          display: flex
          height: 90px
          padding: 0 35px

          .navbar-logo
            padding: 34px 0 24px 0
            transition: all .2s ease

            .navbar-logo-wrap img
              transition: all .2s ease
              width: 240px

          .navbar-menu
            width: 100%
            display: flex
            align-items: center

            .nav
              .menu-item
                display: flex
                align-items: center
                padding: 25px

                a
                  font-family: 'Inter', Arial, sans-serif
                  color: $main-color
                  font-size: $main-font-size
                  line-height: 19px
                  padding: 0
                  &:hover
                    background-color: transparent

                &.current-menu-item a
                  background-color: transparent
                  text-decoration: underline

            .navbar-secondary
              display: flex
              align-items: center
              .navbar-btn
                padding: 0 15px

                .btn-cart
                  svg
                    height: 50px
                    width: 50px

                span
                  position: absolute
                  color: #fff
                  font-size: 14px
                  text-align: center
                  background-color: #1e3246
                  border-color: #fff
                  border-radius: 50%
                  top: 55px
                  right: 40px
                  width: 25px
                  height: 25px
                  line-height: 26px

    .drop
      background: #fff
      border-bottom-right-radius: 5px
      border-bottom-left-radius: 5px
      height: 0
      overflow: hidden
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2)
      transition: 0.4s height

  .header-content-menu
    display: none

@media (max-width: 1439px) and (min-width: 992px)
  #header
    #navigation.affix
      .navbar
        .container-navbar
          width: 986px

          .navbar-wrap
            height: 65px
            padding: 0
            margin-top: 20px

            .navbar-logo
              padding: 20px 15px 20px 20px

              .navbar-logo-wrap img
                width: 145px

            .navbar-menu
              .nav
                .menu-item
                  height: 100%
                  padding: 15px

                  a
                    font-size: 12px

              .navbar-secondary
                .navbar-btn
                  padding: 0 12px
                  .btn-cart
                    svg
                      height: 40px
                      width: 40px

                  span
                    top: 40px
                    right: 6px
                    width: 20px
                    height: 20px
                    line-height: 19px
                    font-size: 12px

    .header-content-overlay
      .header-content-txt
        h1
          font-size: 48px
          line-height: 100%

      .badge-chart
        bottom: 35px
        right: 35px
        height: 54px
        width: 54px
        svg
          height: 22px
          width: 22px

@media (max-width: 460px)
  #header
    #navigation
      display: none

    .header-content-overlay
      background-position-x: 35%
      display: flex
      align-items: center

      .header-content-txt
        padding: 0 15px
        h1
          font-size: 24px
          line-height: 130%
          font-weight: 700
          .mobile-visible
            display: block

      .badge-chart
        bottom: 70px
        right: 20px
        height: 48px
        width: 48px
        svg
          height: 20px
          width: 20px

    .header-content-menu
      display: flex
      justify-content: space-between
      position: fixed
      left: 0
      top: 0
      right: 0
      height: 50px
      color: #fff
      z-index: 50
      width: 100vw
      box-shadow: 0 0 13px -1px rgba(0, 0, 0, 0.17)
      background-color: #343944

      .navbar-btn-toggle
        height: 100%
        width: 50px
        font-size: 38px
        background-color: $main-color
        border: 0
        padding: 10px 12px
        position: relative
        border-radius: 0

      .menu-logo
        display: flex
        flex-grow: 1
        line-height: 46px
        height: 50px
        background-color: #fff
        justify-content: center

      .cart-icon
        width: 50px
        position: relative


        .btn-cart
          cursor: pointer

          .badge-discount
            position: absolute
            color: #fff
            font-size: 14px
            text-align: center
            background-color: #1e3246
            border-color: #fff
            border-radius: 50%
            bottom: -10px
            left: -10px
            width: 25px
            height: 25px
            line-height: 26px
</style>
