<template>
  <div class="service-detail">
    <div class="container-breadcrumbs">
      <div class="breadcrumbs">
        <router-link to="/">Главная</router-link> /
        <router-link to="/services/">Сервис</router-link> /
        <span class="current">{{ service.name }}</span>
      </div>
    </div>

    <div class="service-detail__wrapper" v-if="!!Object.keys(service)">
      <div class="title">{{ service.name }}</div>
      <div class="description">{{ service.description }}</div>
      <div class="price">{{ service.price }} ₽</div>
      <div class="details" v-if="service.details">
        В стоимость входит:
        <div
          class="details__item"
          v-for="(item, index) in service.details"
          :key="index"
        >
          {{ service.details[index] }}
        </div>
      </div>
      <button
        class="add-to-cart flex-center"
        @click="addToCart"
        :disabled="isLoadingAddCart"
      >
        <span v-if="!isLoadingAddCart">В корзину</span>
        <clip-loader :color="'#fff'" v-else size="27px"></clip-loader>
      </button>
    </div>
    <YandexMap />
  </div>
</template>

<script>
import YandexMap from "@/components/YandexMap";
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "ServiceDetail",
  components: { YandexMap, ClipLoader },
  data() {
    return {
      isLoadingAddCart: false,
    };
  },
  computed: {
    service() {
      return JSON.parse(localStorage.getItem("serviceItem"));
    },
  },
  methods: {
    addToCart() {
      this.isLoadingAddCart = true;

      setTimeout(() => {
        this.isLoadingAddCart = false;
      }, 400);

      this.$store.commit("addToCart", this.service);
    },
  },
  unmounted() {
    localStorage.removeItem("serviceItem");
    if (this.$route.name !== "Services") {
      localStorage.removeItem("defService");
    }
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles.sass"
.service-detail
  margin-top: 125px
  margin-bottom: 120px

  &__wrapper
    display: flex
    flex-direction: column
    align-items: center
    width: 500px
    margin: 50px auto 0
    color: $main-color
    font-family: "Inter", sans-serif

    .title
      font-family: "Inter-Bold", "Inter"
      font-size: 30px
      line-height: 110%
      text-align: center

    .description
      color: #858585

    .price
      margin: 30px 0
      font-family: "Inter-Bold", "Inter"
      font-size: 24px
      line-height: 120%

    .details
      line-height: 19px

    .add-to-cart
      height: 54px
      width: 235px
      background-color: $main-color
      color: #fff !important
      font-size: 18px
      margin-top: 30px
      text-transform: none
      text-align: center
      font-weight: 300
      &:disabled
        background-color: #e9e9e9
        color: #e9e9e9
      &:hover
        background-color: #5F6C79
        border-color: #5F6C79

@media (max-width: 1439px) and (min-width: 992px)
  .service-detail
    margin: 90px auto

    &__wrapper
      .title
        font-size: 26px

      .description
        font-size: 14px
        line-height: 17px

      .price
        margin: 25px 0
        font-size: 22px

      .details
        font-size: 12px
        line-height: 150%

      .btn
        width: 226px
        font-size: 16px

@media (max-width: 460px)
  .service-detail
    margin: 80px auto
    padding: 0 15px

    &__wrapper
      margin-top: 30px
      width: 100%

      .title
        font-size: 20px
        line-height: 120%

      .description
        font-size: 14px
        line-height: 17px

      .details
        font-size: 14px
        line-height: 140%

      .btn
        width: 100%
        font-size: 16px

@media screen and (min-width: 3326px)
  .service-detail
    height: calc(100% - 130px)
    display: flex
    flex-direction: column
    justify-content: center
</style>
