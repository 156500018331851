import axios from "axios";

const httpClient = axios.create({
  // baseURL: process.env.VUE_APP_DEV,
  baseURL: "https://porscha.ru/api/v1",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default httpClient;
