<template>
  <div id="not_found">
    <div class="container text-center">
      <span class="pg-404">404</span>
      <h2>Страница не найдена</h2>
      <p>
        Такой страницы не существует,<br />
        или она была удалена
      </p>
      <div class="text-center">
        <button class="button-back" @click="$router.go(-1)">
          <i class="ion ion-chevron-left"></i> Вернуться назад
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles"
#not_found
  height: 100%
  display: flex
  align-items: center
  color: $main-color

  .pg-404
    font-family: 'EuropeExt', sans-serif
    font-size: 128px
    line-height: 118px

  h2
    font-size: 36px
    margin-bottom: 13px

  p
    font-size: 18px
    margin-bottom: 35px
    line-height: 150%
    letter-spacing: 0.5px

  .button-back
    text-transform: none
    text-align: center
    background-color: #fff
    height: 64px
    border-radius: 0
    font-size: 20px
    &:hover
      background-color: #e8ebec
      color: $main-color !important

    .ion-chevron-left
      padding-right: 25px

@media (max-width: 460px)
  #not_found
    .pg-404
      font-size: 52px
      line-height: 48px

    h2
      font-size: 18px
      line-height: 22px
      margin-bottom: 4px

    p
      font-size: 14px
      margin-bottom: 32px
      line-height: 19px

    .button-back
      width: 100%
      height: 54px
      font-size: 16px

@media (max-width: 1439px) and (min-width: 992px)
  #not_found
    .pg-404
      font-size: 57px
      line-height: 48px

    h2
      font-size: 24px
      margin-bottom: 5px

    p
      font-size: 14px
      margin-bottom: 24px

    .button-back
      width: 290px
      height: 54px
      font-size: 17px
</style>
