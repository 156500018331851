export default {
  mounted: (el, ...args) => {
    const loadData = (type) => {
      const defType = () => false;
      const loadComponent = () => (args[0].instance.load = true);
      const loadImage = () => {
        const imageElement = Array.from(el.children).find(
          (el) => el.nodeName === "IMG"
        );
        if (imageElement && imageElement.dataset.url) {
          imageElement.addEventListener("load", () => {
            setTimeout(() => el.classList.add("loaded"), 100);
          });
          imageElement.addEventListener("error", () => console.log("error"));
          imageElement.src = imageElement.dataset.url;
        }
      };
      const types = {
        image: loadImage,
        component: loadComponent,
      };
      return (types[type] || defType)();
    };

    const handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        } else {
          loadData(args[0].value);
          observer.unobserve(el);
        }
      });
    };

    const createObserver = () => {
      const options = {
        root: null,
        threshold: "0",
      };

      const observer = new IntersectionObserver(handleIntersect, options);

      observer.observe(el);
    };

    if (!window.IntersectionObserver) {
      loadData(args[0].value);
    } else {
      createObserver();
    }
  },
};
