<template>
  <div v-lazy-load="'component'" class="container-map text-center">
    <h1>Как до нас добраться?</h1>
    <p>
      Мы находимся по адресу:
      <span class="text-decoration-underline">Москва, Угловая улица, 9г</span>
    </p>
    <yandex-map
      v-if="load"
      id="yandexMap"
      ref="map"
      :coordinates="coords"
      :zoom="10"
      style="width: 100%; height: 355px"
      :controls="[]"
    >
      <yandex-marker
        marker-type="placemark"
        :coordinates="coords"
        :marker-id="1"
      />
    </yandex-map>
  </div>
</template>

<script>
import { YandexMap, YandexMarker } from "vue-yandex-maps";

export default {
  name: "yandexMapView",
  components: {
    YandexMap,
    YandexMarker,
  },
  data() {
    return {
      load: false,
      coords: [55.921077, 37.669432],
    };
  },
};
</script>

<style scoped lang="sass">
.container-map
  margin-top: 120px
  width: 1320px

@media (max-width: 460px)
  .container-map
    margin-top: 60px
    width: 100%

@media (max-width: 1440px) and (min-width: 992px)
  .container-map
    width: 955px
    margin-top: 80px
</style>
