<template>
  <div id="product">
    <div class="container-breadcrumbs">
      <div class="breadcrumbs">
        <router-link to="/">Главная</router-link> /
        <router-link to="/catalog/">Каталог</router-link> /
        <span class="current">
          {{ product.category_name ?? "Все товары" }} /
        </span>
        <span class="current">{{ product.name }} </span>
      </div>
    </div>
    <div class="box-product">
      <div class="box-product__wrapper" v-if="!isLoadingProduct">
        <div class="box-img-product" v-lazy-load="'image'">
          <img
            :data-url="
              product.image ? `https://porscha.ru${product.image}` : ''
            "
            src="../../assets/images/product/no_image.webp"
            align="left"
            alt=""
          />
        </div>
        <div class="box-info-product">
          <div class="name-product">{{ product.name }}</div>
          <div class="code">Код: {{ product.code }}</div>
          <div class="product-description">
            <p class="text">
              Наши менеджеры готовы проконсультировать Вас по любому вопросу и
              при необходимости привлечь технического специалиста. Также, Вы
              можете установить запчасть в нашем автосервисе.
            </p>
            <p class="text">
              Осуществляем доставку запчастей по всей РФ. При заказе от 5000 -
              БЕСПЛАТНАЯ доставка по Москве.
            </p>
            <p class="text">
              Гарантия на новые запчасти – 1 год. На Б/У товары – 2 недели на
              проверку.
            </p>
            <p class="text">
              Наша компания специализируется на ремонте и запчастях Порше. Мы не
              продаем то, что не нужно. Порой даже отговариваем от покупки, если
              запрашиваемая деталь точно не решит проблему с автомобилем. Мы
              предоставляем качественный и честный сервис не только в ремонте,
              но и в продажах.
            </p>
            <p class="text">
              *все фото, представленные на сайте, являются примером
            </p>
          </div>
          <div class="price" v-if="product.price_with_sale">
            <span>{{ product.price.toLocaleString() }} &#x20bd;</span>
            {{ product.price_with_sale.toLocaleString() }} &#x20bd;
          </div>
          <div class="price" v-else>
            {{ product.price.toLocaleString() }} &#x20bd;
          </div>
          <button
            class="add-to-cart"
            @click="addToCart"
            :disabled="isLoadingAddCart"
          >
            <span v-if="!isLoadingAddCart">В корзину</span>
            <clip-loader :color="'#fff'" v-else size="27px"></clip-loader>
          </button>
          <div
            class="box-info-product__navigate"
            v-show="!!product.prev_slug || !!product.next_slug"
          >
            <button
              v-show="!!product.prev_slug"
              class="box-info-product__navigate-prev"
              @click="getProduct(product.prev_slug)"
            >
              <i class="ion ion-chevron-left"></i>
              Назад
            </button>
            <button
              v-show="!!product.next_slug"
              class="box-info-product__navigate-next"
              @click="getProduct(product.next_slug)"
            >
              Вперед
              <i class="ion ion-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <clip-loader :color="'#1e3246'" v-else></clip-loader>
    </div>

    <YandexMap />
  </div>
</template>

<script>
import YandexMap from "@/components/YandexMap";
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "ProductDetail",
  components: {
    YandexMap,
    ClipLoader,
  },
  data() {
    return {
      product: [],
      isLoadingProduct: true,
      isLoadingAddCart: false,
    };
  },
  methods: {
    addToCart() {
      this.isLoadingAddCart = true;

      setTimeout(() => {
        this.isLoadingAddCart = false;
      }, 400);

      this.$store.commit("addToCart", this.product);
    },
    getProduct(slug) {
      this.$store.dispatch("getProduct", slug);
    },
  },
  created() {
    this.getProduct(this.$route.params.slug);
  },
  watch: {
    "$store.state.product": {
      handler(product) {
        this.product = product;
        this.isLoadingProduct = false;
      },
      deep: true,
    },
  },
};
</script>
<style scoped lang="sass">
@import "src/assets/sass/styles"
#product
  margin-top: 125px
  margin-bottom: 130px

  .box-product
    width: 1320px
    overflow: hidden
    margin: 0 auto

    &__wrapper
      display: flex
      justify-content: space-between

      .box-img-product
        display: flex

        img
          margin: auto

      .box-img-product,
      .box-info-product
        width: 648px

      .box-info-product
        display: flex
        flex-direction: column

        .name-product
          font-weight: 600
          font-size: 30px
          line-height: 110%
          margin-bottom: 10px

        .code
          font-size: $main-font-size
          line-height: 125%
          color: #858585
          margin-bottom: 30px

        .product-description
          font-size: 16px
          text-align: left

          .text
            margin: 0 0 10px

            &:last-child
              font-weight: bold
              margin: 0

        .price
          font-weight: 700
          font-size: 24px
          line-height: 122%
          margin: 35px 0

        .add-to-cart
          background-color: $main-color
          color: #fff !important
          font-size: 18px
          font-weight: 500
          width: 235px
          height: 54px
          align-items: center
          text-align: center
          text-transform: none
          display: flex
          justify-content: center

          &:disabled
            background-color: #e9e9e9
            color: #e9e9e9

          &:hover
            background-color: #5f6c79
            border: 0

        .box-info-product__navigate
          margin-top: auto
          height: 36px

          &-next, &-prev
            height: 36px
            width: 115px
            background-color: #fff
            border: 1px solid $main-color
            border-radius: $border-radius
            font-size: $main-font-size
            line-height: 19px
            padding: 8px 20px
            text-align: center
            font-weight: normal
            .ion-chevron-right,
            .ion-chevron-left
              font-size: 14px

            &:hover
              background-color: #E8EBEC
              color: $main-color!important

          &-next
            margin-left: 10px

@media (max-width: 1439px) and (min-width: 992px)
  #product
    margin: 95px 0 100px

    .box-product
      width: 956px
      &__wrapper
        .box-img-product,
        .box-info-product
          width: 470px

          .name-product
            font-size: 26px

          .product-description
            margin: 13px 0 0
            font-size: 14px

          .code
            font-size: 14px
            margin-bottom: 10px

          .price
            margin: 33px 0
            font-size: 22px

          .add-to-cart
            font-size: $main-font-size
            width: 226px

          .box-info-product__navigate
            &-next, &-prev
              width: 122px


@media (max-width: 460px)
  #product
    padding: 0 15px
    margin: 80px 0 50px

    .box-product
      width: 100%
      &__wrapper
        flex-wrap: wrap
        .box-img-product,
        .box-info-product
          min-width: 100%
          height: fit-content
          margin-bottom: 20px

        .box-img-product
          max-height: 260px
          height: 100%
          overflow: hidden

        .box-info-product
          text-align: center
          .name-product
            font-size: 20px
            line-height: 120%

          .product-description
            margin: 20px 0 0
            font-size: 14px

          .code
            font-size: 14px
            margin-bottom: 10px

          .price
            margin: 20px 0 30px

          .add-to-cart
            font-size: $main-font-size
            line-height: 19px
            width: 100%
            margin-bottom: 25px

          .box-info-product__navigate
            display: flex
            &-next, &-prev
              width: 50%

            &-next
              margin-left: 15px

@media (min-width: 1920px)
  #product
    display: grid

@media screen and (min-width: 3326px)
  #product
    height: calc(100% - 130px)
    display: flex
    flex-direction: column
    justify-content: center
</style>
