import "bootstrap/dist/css/bootstrap.min.css";
import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import axios from "axios";
import "./assets/sass/styles.sass";
import "./assets/css/style.css";
import LazyLoadDirective from "@/directives/LazyLoadDirective";
import ClipLoader from "vue-spinner/src/ClipLoader";

axios.defaults.baseURL = process.env.VUE_APP_ROOT;

const YandexMap = defineAsyncComponent({
  loader: () => import("@/components/YandexMap.vue"),
  loadingComponent: ClipLoader,
});

createApp(App)
  .use(store)
  .use(router)
  .component("YandexMapComp", YandexMap)
  .directive("lazy-load", LazyLoadDirective)
  .mount("#app");
