<template>
  <div id="contacts" class="h-100">
    <div
      class="container h-100 d-flex flex-column justify-content-center text-center"
    >
      <h1 class="h1-contacts">Контакты</h1>
      <SocialLinks dark="dark" />
      <div class="tel" v-for="(phone, index) in phoneData" :key="index">
        <span>{{ phone.title }}</span>
        <a :href="phone.href">{{ phone.number }}</a>
      </div>
      <h4>Наши Реквизиты</h4>
      <div class="requisites">
        <div
          class="req"
          :class="{ last: index === 2 }"
          v-for="(requisite, index) in requisitesData"
          :key="index"
        >
          <span class="title">{{ requisite.title }}</span>
          <span class="description">{{ requisite.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialLinks from "../components/SocialLinks";

export default {
  name: "Contacts",
  components: { SocialLinks },
  computed: {
    phoneData() {
      return [
        {
          title: "Сервис:",
          number: "8 (926) 246-70-56",
          href: "tel:89262467056",
        },
        {
          title: "Продажи:",
          number: "8 (925) 755-77-23",
          href: "tel:89257557723",
        },
        { title: "", number: "8 (495) 505-00-38", href: "tel:84955050038" },
      ];
    },
    requisitesData() {
      return [
        {
          title: "ИНН:",
          description: "100100336906",
        },
        {
          title: "ОГРНИП:",
          description: "305100131800100",
        },
        {
          title: "Наименование:",
          description: `ИП СТАПЕЛЬ АНДРЕЙ БОРИСОВИЧ`,
        },
      ];
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles"
#contacts
  .container
    color: $main-color
    .h1-contacts
      font-style: normal
      font-weight: 700
      font-size: 60px
      line-height: 55px
      text-align: center
      margin-bottom: 30px
      margin-top: 160px
    h4
      text-transform: uppercase
      margin-top: 96px
      margin-bottom: 8px
      font-size: 32px
    .tel
      width: 350px
      font-size: 18px
      margin: 0 auto 25px
      display: flex
      justify-content: space-between
      &.first
        margin-bottom: 25px
      &.last
        margin-bottom: 0
      span
        font-weight: 600
      a
        text-align: left
        width: 165px
    .requisites
      max-width: 550px
      margin: 0 auto 50px
      box-shadow: inset -2px 2px 2px rgba(0,0,0,.05)
      border: 1px solid #bdbdbd
      padding: 10px
      .req
        display: flex
        font-size: 18px
        margin: 0 auto 10px
        text-align: left
        span
          margin: 10px auto 0
          line-height: normal
        span:first-child
          font-weight: 600
          display: inline-block
          width: 155px
        span:last-child
          color: #bdbdbd
        span[class=description]
          display: flex
          align-items: center
          color: $main-color
          margin: 0 0 0 25px
        span[class=title]
          margin: 0 0 0 90px
          max-width: 160px
          width: 100%
        &.last
          position: relative
          margin-bottom: 0
        &.last .description
          width: 324px
          white-space: pre-line
          text-align: left
      .description
        width: 165px

@media (max-width: 1439px) and (min-width: 992px)
  #contacts
    .container
      .h1-contacts
        margin-bottom: 15px
        font-size: 32px
      h4
        font-size: 20px
      .requisites
        max-width: 400px
        .req
          font-size: 14px
          span[class=title]
            margin: 0 0 0 40px
          span[class=description]
            margin: 0 0 0 5px
      .tel
        width: 300px
        font-size: 14px
        &:not(:nth-child(3))
          margin-bottom: 0
        a
          width: 135px
@media (max-width: 460px)
  #contacts
    .container
      align-self: center

      .h1-contacts
        font-size: 22px
        line-height: 24px

      .tel
        width: 260px
        font-size: 14px
        a
          width: 135px
        .description
          width: 135px
        &.last .description
          width: 135px
          position: static
      .requisites
        width: 100%
        .req
          width: 260px
          font-size: 14px
          span:first-child
            width: 139px
          &.last .description
            width: 165px
          span[class=title]
            margin: 0
          span[class=description]
            margin: 0 0 0 20px
</style>
