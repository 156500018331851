<template>
  <div class="product-card" :key="product.id">
    <div class="product-wrapper">
      <figure class="post-thumbnail post-item-thumbnail">
        <a
          class="post-thumbnail__link"
          :class="{ no_image: !product.image }"
          v-lazy-load="'image'"
          :href="`/catalog/product/${product.slug}`"
        >
          <img
            class="post-thumbnail__link-img"
            :data-url="product.image"
            src="../../assets/images/product/no_image.webp"
            alt="Thumbnail"
          />
        </a>
      </figure>
      <a :href="`/catalog/product/${product.slug}`">
        <div class="title text-ellipsis">{{ name }}</div>
      </a>
      <div class="bottom-box">
        <div class="price">{{ price }}&#x20bd;</div>
        <button class="cart" @click="addToCart" :disabled="isLoadingAddCart">
          <svg height="32" width="32" v-if="!isLoadingAddCart">
            <use
              :xlink:href="'../../img/icons/socialSprite.svg#chart_mini'"
            ></use>
          </svg>
          <clip-loader
            class="spinner-wrapper"
            :color="'#fff'"
            size="16px"
            v-else
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
export default {
  name: "ProductsList",
  props: ["product"],
  components: { ClipLoader },
  data() {
    return {
      isLoadingAddCart: false,
    };
  },
  computed: {
    price() {
      return this.product.price.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },
    name() {
      return this.product.name.substring(0, 120);
    },
  },
  methods: {
    addToCart() {
      this.isLoadingAddCart = true;

      setTimeout(() => {
        this.isLoadingAddCart = false;
      }, 400);

      this.$store.commit("addToCart", this.product);
    },
  },
};
</script>
<style scoped lang="sass">
@import "@/assets/sass/styles.sass"
::selection
  background-color: transparent
.product-card
  .product-wrapper
    a
      text-decoration: none
      &:hover
        color: $main-color !important

    .post-thumbnail
      height: 168px
      &__link
        display: flex
        height: 100%
        overflow: hidden
        border-radius: 5px 5px 0 0
        &-img
          width: 100%
          margin: 0
        &.no_image
          background-color: #e7e7e7

  &:hover
    .product-wrapper
      margin-top: 0
      margin-bottom: 30px
      transition: all 0.25s ease-in-out

  .title
    -webkit-line-clamp: 3
    line-height: 19px
    max-width: 100%
    height: 56px
    margin: 15px

  .bottom-box
    display: flex
    justify-content: space-between
    padding: 15px
    align-items: center

    .spinner-wrapper
      width: 32px
      height: 32px
      display: flex
      align-items: center
      justify-content: center

    .price
      font-weight: 700
      height: fit-content
      color: $main-color

    .cart
      display: flex
      align-items: center
      justify-content: center
      width: 32px
      height: 32px
      padding: 0
      transition: none

      &:hover
        border-color: #5f6c79
        background: #5f6c79
        svg
          stroke: #5f6c79
          fill: #5f6c79

  .product-wrapper
    width: 224px
    height: 315px
    margin: 15px 0 15px 30px
    box-shadow: 0 0 20px -7px rgba(0, 0, 0, 0.25)
    transition: margin 0.25s ease-in-out
    border-radius: 4px

@media (max-width: 460px)
  .product-card
    width: 100%
    height: fit-content
    margin-right: 0

    &:hover
      .product-wrapper
        margin: 15px 15px 0 15px
        transition: none

    .title
      font-size: 16px
      line-height: 19px
      height: 55px

    .bottom-box
      padding-top: 0
      .price
        font-size: 18px

    .product-wrapper
      width: auto
      height: auto
      margin: 15px 15px 0 15px

      .post-thumbnail
        height: 174px

      .bottom-box
        height: 100%
        display: flex
        padding-bottom: 15px
        justify-content: space-between

@media (max-width: 1439px) and (min-width: 992px)
  .product-card
    width: 227px
    height: 307px
    margin-left: 15px
    margin-bottom: 40px

    .product-wrapper
      width: 227px
      height: 307px
      margin: 8px 0 8px 16px

    .title
      font-size: 14px
      line-height: 17px
      height: 53px

    .bottom-box
      padding-top: 7px
      .price
        font-size: 14px
        line-height: 17px
</style>
