<template>
  <div id="blogs">
    <div class="container-breadcrumbs">
      <div class="breadcrumbs">
        <router-link to="/">Главная</router-link> <span class="sep">/</span>
        <span class="current">Блог</span>
      </div>
    </div>
    <div class="container-navbar">
      <section class="col-xs-12 col-lg-12" v-if="blogs.results">
        <div
          class="affa-post2 post-thumbnail-left"
          v-for="blog in paginatedBlogs"
          :key="blog.id"
        >
          <div class="row">
            <div class="col-xs-12 col-sm-6 col-md-6-img">
              <figure
                class="post-thumbnail post-item-thumbnail"
                v-if="!!blog.image"
              >
                <router-link :to="`/blog/${blog.slug}`">
                  <img :src="blog.image" alt="blog.name" />
                </router-link>
              </figure>
            </div>
            <div class="col-sm-6 col-md-6-text">
              <div class="post-item-txt">
                <div class="created-blog">
                  {{ blog.created.toLocaleString() }}
                </div>
                <router-link
                  :to="{ name: 'Blog', params: { slug: blog.slug } }"
                >
                  <div class="title text-ellipsis two-rows-text">
                    {{ blog.name }}
                  </div>
                </router-link>

                <div class="entry-content post-item-content">
                  <div
                    class="text-ellipsis"
                    v-html="
                      blog.description.replace(/<(.*?)>|\\n|\\r|&nbsp;/gm, '')
                    "
                  ></div>
                </div>
                <router-link :to="`/blog/${blog.slug}`" class="more-link">
                  Читать дальше
                  <i class="ion ion-ios-arrow-right"></i>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="container text-center">
          <button
            class="button-load-more"
            @click="loadMore"
            v-if="currentPage * maxPerPage < blogs.results.length"
          >
            Показать ещё
          </button>
        </div>
      </section>
      <clip-loader :color="'#1e3246'" :loading="isLoadingBlogs"></clip-loader>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "Blogs",
  components: { ClipLoader },
  data() {
    return {
      blogs: {},
      currentPage: 1,
      maxPerPage: 3,
      isLoadingBlogs: true,
    };
  },
  created() {
    this.getBlogs();
  },
  computed: {
    totalResults() {
      return Object.keys(this.blogs.results).length;
    },
    pageCount() {
      return Math.ceil(this.totalResults / this.maxPerPage);
    },
    paginatedBlogs() {
      return this.blogs.results.slice(0, this.currentPage * this.maxPerPage);
    },
  },
  methods: {
    getBlogs() {
      this.$store.dispatch("getBlogs");
    },
    loadMore() {
      this.currentPage += 1;
    },
  },
  watch: {
    "$store.state.blogs": {
      handler(blogs) {
        this.blogs = blogs;
        if (blogs.results.length) {
          this.blogs.results = blogs.results;
        }
        this.isLoadingBlogs = false;
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles.sass"
#blogs
  height: 100%
  margin-top: 123px
  display: flex
  flex-direction: column

  .container-navbar
    display: flex
    height: 100%
    justify-content: center
    width: 1320px

    .affa-post2
      margin-bottom: 50px

      &.post-thumbnail-left
        .post-item-txt
          height: 100%
          color: $main-color

          .created-blog
            color: $main-color
            margin-bottom: 8px
            font-weight: 400
            font-size: $main-font-size
            line-height: 19px
            opacity: 0.5

          .title
            font-style: normal
            color: $main-color
            font-weight: 700
            font-size: 30px
            margin-bottom: 20px
            line-height: 33px

          .post-item-content
            height: 302px
            font-style: normal
            font-weight: 400
            font-size: 18px
            line-height: 27px

            div
              -webkit-line-clamp: 10

          .more-link
            font-weight: 700
            font-size: 18px
            position: absolute
            line-height: 22px
            bottom: 0
            display: flex
            align-items: center

            .ion-ios-arrow-right
              &:before
                content: "\f3d3"
                font-size: 20px
                font-weight: 900

            .ion
              margin-left: 10px
              &:hover
                text-decoration: underline

    .container
      .button-load-more
        height: 64px
        font-size: 20px
        line-height: 19px

@media (max-width: 460px)
  #blogs
    width: 100%
    margin-top: 80px

    .container-navbar
      width: inherit
      padding: 0
      margin: 0

      .affa-post2
        .col-md-6-img
          padding: 0 15px

        .col-md-6-text
          .post-item-txt
            .title
              font-weight: 600
              font-size: 20px
              line-height: 120%

            .created-blog
              font-size: 14px

            .post-item-content
              margin-bottom: 20px
              height: 230px
              font-size: 14px
              font-weight: 500
              line-height: 140%

              span
                -webkit-line-clamp: 11

            .more-link
              position: unset
              text-decoration: underline
              font-size: 14px
              line-height: 17px

      .container
        width: 100%
        padding: 0

        .button-load-more
          margin: 0 0 50px
          width: inherit

@media (max-width: 1439px) and (min-width: 992px)
  #blogs
    .container-navbar
      width: 986px
      .affa-post2
        margin-bottom: 30px

        &.post-thumbnail-left
          .post-item-txt
            .created-blog
              font-size: 14px

            .title
              font-size: 24px
              margin-bottom: 5px

            .post-item-content
              height: 186px
              line-height: 150%
              font-size: 14px

              span
                -webkit-line-clamp: 8

            .more-link
              font-size: 14px

      .container
        .button-load-more
          width: 260px
          height: 54px
          font-size: 16px
          margin-top: 10px
          margin-bottom: 90px

@media screen and (min-width: 3326px)
  #blogs
    height: calc(100% - 130px)
    display: flex
    flex-direction: column
    justify-content: center

    .container-navbar
      height: unset
</style>
