<template>
  <div id="checkout">
    <div class="container-checkout" v-if="products.length > 0">
      <div class="box-products-checkout">
        <div class="checkout-title">Моя корзина</div>
        <CartItemsCheckout
          v-for="product in products"
          :key="product.id"
          :product="product"
        />
      </div>
      <div class="box-info-checkout">
        <div class="checkout-title">Детали заказа</div>
        <!--        <hr />-->
        <div class="summa">
          Сумма <span>{{ cartCost.toLocaleString() }} &#x20bd;</span>
        </div>
        <div class="hr"></div>
        <div class="itogo">
          Итого
          <span>{{ totalCost.toLocaleString() }} &#x20bd;</span>
        </div>
        <div
          class="form-group clearfix d-flex align-items-center justify-content-between"
        >
          <button class="checkout-btn" @click="showModal = true">
            Оформить заказ
          </button>
        </div>
        <OrderModel v-show="showModal" @close-modal="showModal = false" />
      </div>
    </div>
    <div class="container-checkout" v-else>Корзина пуста</div>
  </div>
</template>

<script>
import CartItemsCheckout from "@/components/cart/CartItemsCheckout";
import OrderModel from "@/components/OrderModel";
export default {
  name: "Checkout",
  components: { OrderModel, CartItemsCheckout },
  data() {
    return {
      delivery_cost: 250,
      showModal: false,
    };
  },
  computed: {
    products() {
      return this.$store.getters.cartItems;
    },
    totalCost() {
      return this.$store.getters.totalCostOrder;
    },
    cartCost() {
      return this.$store.getters.totalCostOrder;
    },
  },
  watch: {
    "$store.state.cart": {
      handler(val) {
        this.products = val;
      },
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/assets/sass/styles.sass"
#checkout
  margin-top: 190px
  height: fit-content

  hr
    margin-top: 0
    margin-bottom: 30px

  .container-checkout
    display: flex
    width: 1096px
    min-height: 300px
    margin: 0 auto

    .box-products-checkout
      width: 100%
      .checkout-title
        font-size: 18px
        line-height: 27px
        color: $main-color
        letter-spacing: .6px
        margin-bottom: 20px

  .box-info-checkout
    max-width: 310px
    width: 100%
    margin-left: 110px
    line-height: 120%
    color: #000

    .checkout-title
      font-size: 18px
      line-height: 27px
      color: $main-color
      letter-spacing: .6px
      padding-bottom: 20px
      margin-bottom: 20px
      border-bottom: 1px solid #BDBDBD

    .summa,
    .dostavka
      font-weight: 400
      margin-bottom: 15px
      letter-spacing: .6px

      span
        font-weight: 700
        float: right

    .address
      text-decoration-line: underline
      margin-top: 32px
      letter-spacing: .4px

    .hr
      border-bottom: 1px solid #BDBDBD
      margin: 30px 0

    .itogo
      span
        float: right
        font-weight: 700
        font-size: 19px

    .form-group
      margin-bottom: 15px

      .checkout-btn
        color: #fff !important
        background-color: $main-color
        width: 100%
        height: 48px
        text-align: center
        text-transform: none
        font-weight: 400
        font-size: 16px
        padding: 10px 15px
        margin-top: 30px

@media (max-width: 1439px) and (min-width: 992px)
  #checkout
    margin-top: 140px

    hr
      margin-bottom: 25px

    .container-checkout
      width: 794px
      min-height: 300px

      .box-products-checkout
        .checkout-title
          margin-bottom: 15px

    .box-info-checkout
      max-width: 230px
      width: 100%
      margin-left: 95px
      font-size: 14px

      .hr
        margin-bottom: 25px

      .checkout-title
        padding-bottom: 15px
        margin-bottom: 15px

      .itogo
        span
          font-size: 14px

      .form-group
        .checkout-btn
          height: 54px

@media (max-width: 460px)
  #checkout
    padding: 0 15px
    margin-top: 90px

    hr
      margin-bottom: 20px

    .container-checkout
      width: 100%
      height: 100%
      flex-wrap: wrap

      .box-products-checkout
        .checkout-title
          margin-bottom: 15px

    .box-info-checkout
      margin-left: 0
      max-width: unset
      font-size: 14px

      .hr
        margin-bottom: 20px

      .checkout-title
        padding-bottom: 15px
        margin-bottom: 20px

      .itogo
        span
          font-size: 14px

      .form-group
        .checkout-btn
          height: 54px
          margin-bottom: 60px
</style>
