<template>
  <div id="single_blog">
    <div class="container-breadcrumbs">
      <div class="breadcrumbs">
        <router-link to="/">Главная</router-link> /
        <router-link to="/blog/">Блог</router-link> / {{ blog.name }}
      </div>
    </div>
    <div class="container-post">
      <div class="single" v-if="blog.name">
        <figure
          class="post-thumbnail post-single-thumbnail"
          v-if="!!blog.image"
        >
          <img :src="blog.image" :alt="blog.name" />
        </figure>
        <div class="single-created-blog">{{ blog.created }}</div>
        <div class="entry-title post-single-title text-ellipsis">
          {{ blog.name }}
        </div>
        <div
          class="entry-content post-single-content"
          v-html="blog.description"
        />
        <div class="comments">
          <div class="comments-create">
            <textarea
              class="comments-input"
              type="text"
              rows="1"
              v-model="comment"
              placeholder="Оставить комментарий"
              @input="checkAreaValue($event.target.value)"
              @focus="checkAreaValue($event.target.value)"
            />
            <div class="comments-btn">
              <button
                class="btn-custom"
                :disabled="buttonDisable"
                @click="sendComment"
              >
                Отправить
              </button>
              <button class="btn-custom" @click="clearComment">Отмена</button>
            </div>
          </div>
          <div
            class="comments-list"
            v-for="(comment, index) in comments"
            :key="index"
          >
            <div class="comments-list__date">
              Гость
              <span class="date">{{ comment.created }}</span>
            </div>
            <div class="comments-list__text">{{ comment.text }}</div>
          </div>
        </div>
      </div>
      <clip-loader class="spinner" :color="'#1e3246'" v-else></clip-loader>
    </div>
  </div>
</template>

<script>
import ClipLoader from "vue-spinner/src/ClipLoader";

export default {
  name: "BlogSingle",
  components: { ClipLoader },
  data() {
    return {
      blog: {},
      comment: "",
      comments: [],
      buttonDisable: true,
    };
  },
  created() {
    this.getBlog();
  },
  methods: {
    getBlog() {
      this.$store.dispatch("getBlog", this.$route.params.slug);
    },
    clearComment() {
      if (this.comment.length > 0) this.comment = "";
      this.buttonDisable = true;
    },
    sendComment() {
      this.$store.dispatch("createComment", {
        text: this.comment,
        post: this.blog.id,
      });
      this.buttonDisable = true;
    },
    checkAreaValue(value) {
      this.buttonDisable = value.trim().length < 1;
    },
  },
  watch: {
    "$store.state.blog": {
      handler(blog) {
        this.blog = blog;
        this.$store.dispatch("getComments", this.blog.id);
      },
      deep: true,
    },
    "$store.state.comments": {
      handler(comments) {
        this.comments = comments;
        this.clearComment();
      },
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles.sass"
#single_blog
  display: flex
  flex-direction: column

  .container-post
    justify-content: center
    display: flex
    margin: 0 auto

    .single
      display: flex
      flex-direction: column
      align-items: center
      width: 648px
      color: $main-color

      .post-single-thumbnail
        max-height: 441px
        overflow: hidden
        margin-bottom: 26px
        margin-left: auto
        margin-right: auto

      .single-created-blog
        margin-bottom: 5px
        font-weight: 400
        font-size: $main-font-size
        line-height: 19px
        color: #858585
        opacity: 0.5
        align-self: start

      .post-single-title
        word-break: break-word
        -webkit-line-clamp: 3
        font-size: 30px
        line-height: 33px
        font-weight: 700
        align-self: start

      .post-single-content
        font-weight: 400
        font-size: 18px
        line-height: 150%
        margin-bottom: 40px

      .comments
        width: 100%

        .comments-create
          width: initial
          margin-bottom: 30px

          .comments-input
            width: 100%
            line-height: 150%
            resize: none
            border: none
            padding-left: 0
            box-shadow: none
            border-bottom: 1px solid #858585

          .comments-btn
            text-align: end

            .btn-custom
              height: 30px
              padding: 5px
              font-size: 14px
              font-weight: normal
              line-height: 16px
              color: #fff !important
              width: 130px
              text-align: center
              &:hover
                border-color: #5f6c79
                background-color: #5f6c79
              &:last-child
                margin-left: 10px
              &:disabled
                cursor: pointer
                color: $main-color !important
                &:hover
                  color: #fff !important

        .comments-list
          margin: 30px 0
          font-size: 13px
          color: $main-color
          .date
            margin-left: 15px
            color: #858585

@media (max-width: 460px)
  #single_blog
    padding: 0 15px
    height: 100%

    .container-post
      width: 100%
      height: inherit

      .spinner
        margin: auto

      .single
        width: inherit

        .post-single-thumbnail
          width: 100%

        .single-created-blog
          font-size: 14px

        .post-single-title
          font-weight: 600
          font-size: 20px
          line-height: 120%
          margin-bottom: 15px

        .post-single-content
          font-weight: 500
          font-size: 14px
          line-height: 140%
          width: 100%

        .comments
          .comments-create
            .comments-input,
            .comments-btn
              font-weight: 400
              font-size: 14px
              line-height: 16px

            .comments-btn
              display: flex

              .btn-custom
                height: 36px
                width: 50%

@media (max-width: 1439px) and (min-width: 992px)
  #single_blog
    .container-post
      .single
        width: 470px

        .post-single-title
          font-size: 24px
          margin-bottom: 10px

        .single-created-blog
          font-size: 14px

        .post-single-content
          font-size: 14px
          width: inherit
          p
            img
              height: auto

        .comments
          .comments-create
            .comments-input,
            .comments-btn
              font-size: 14px

            .comments-btn
              .btn-custom
                height: 36px
                width: 140px
</style>
