<template>
  <div
    class="custom-select"
    :class="{ open: open }"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div class="selected" @click="open = !open">
      {{ selected.name }}
    </div>
    <div class="items" :class="{ selectHide: !open, 'd-none': !isNotOpen }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="setSort(option)"
        :class="{ selected: option.name === selected.name }"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: Object,
      required: false,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: { name: "Все" },
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0].name
        : null,
      open: false,
    };
  },
  computed: {
    isNotOpen() {
      return !!this.options.length;
    },
  },
  methods: {
    setSort(option) {
      this.selected = option;
      this.open = !this.open;
      this.$emit("input", option);
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles"
.custom-select
  position: relative
  width: 183px
  height: 32px
  outline-style: none

  &.open
    .selected
      &:after
        transform: rotateZ(90deg)
        transition: all 0.2s ease-in-out

  .selected
    border: 1px solid $main-color
    border-radius: 2px
    color: $main-color !important
    font-weight: 400
    font-size: 14px
    line-height: 16px
    padding: 6px 13px 7px 13px
    cursor: pointer
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none

    &.open
      border: 1px solid $main-color

    &:after
      position: absolute
      content: '\276E'
      color: $main-color
      top: 8px
      right: 15px
      width: fit-content
      height: fit-content
      transform: rotateZ(270deg)
      transition: all 0.2s ease-in-out

  .items
    color: $main-color
    border: 1px solid $main-color
    border-radius: 2px
    overflow: hidden
    margin-top: 7px
    position: absolute
    background-color: #fff
    left: 0
    right: 0
    z-index: 10

    .selected
      background-color: #e8ebec !important
      border: none !important
      &:after
        content: none

    div
      color: $main-color
      padding: 7px 10px
      font-weight: 400
      font-size: 14px
      line-height: 16px
      cursor: pointer
      user-select: none

      &:hover
        background-color: $default-color !important

@media (max-width: 1439px) and (min-width: 992px)
  .custom-select
    .selected,
    .items > div
      font-size: 12px

@media (max-width: 460px)
  .custom-select
    .selected
      height: inherit
      line-height: 25px

    .items
      div
        height: inherit
        line-height: 25px
</style>
