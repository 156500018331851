import httpClient from "./http/index.js";

const config = () => {
  return {
    headers: {
      "X-CSRFToken": document.cookie.slice(
        document.cookie.indexOf("=") + 1,
        document.cookie.length
      ),
    },
  };
};

const appService = {
  async getProduct(slug) {
    return httpClient.get(`/product/${slug}/`);
  },

  async getProducts(query) {
    return httpClient.get(`/products/list/?${query}`);
  },

  async getCategories() {
    return httpClient.get("/categories/list/");
  },

  async getBlogs() {
    return httpClient.get("/blogs/list/");
  },

  async getBlog(id) {
    return httpClient.get(`/blog/${id}/`);
  },

  async saveComment(body) {
    return httpClient.post("/comment/add/", body, config());
  },

  async getComments(id) {
    return httpClient.get(`/comments/${id}/post/?page=1`);
  },

  async getRecommendProduct() {
    return httpClient.get(`/recommended/products/list/?page=1`);
  },

  async getCatalog() {
    return httpClient.get("/catalog/list/");
  },

  async sendMessage(body) {
    return httpClient.post("/send/message/", body, config());
  },

  async sendCart(body) {
    return httpClient.post("/create/order/", body, config());
  },

  async getServices() {
    return httpClient.get("/services/category/");
  },

  async getServiceList(params) {
    return httpClient.get(`/services/${params.id}/?page=${params.page}`);
  },
};

export default appService;
