<template>
  <div id="home">
    <div class="container">
      <div class="catalog">
        <h1>Каталог товаров</h1>
        <p>
          Оригинальные запчасти Porsche с гарантией. Мы бесплатно доставляем
          детали по Москве,<br />
          при заказе от 5000 рублей, а также отправим запчасти в любой регион
          России и стран СНГ. <br />
          Мы работаем на совесть, и всегда готовы на долгосрочное
          сотрудничество!
        </p>
      </div>
      <Carousel
        :settings="settings"
        :breakpoints="breakpoints"
        v-if="cat.length"
      >
        <Slide v-for="slide in cat" :key="slide.id">
          <div class="carousel__item" @click="setCategory(slide.id)">
            <span class="card-arrow"></span>
            <div class="card-box" v-lazy-load="'image'">
              <img
                class="card-image"
                :data-url="slide.image"
                :alt="slide.name"
                src="../assets/images/product/no_image.webp"
              />
            </div>
            <div class="title-item-slider">{{ slide.name }}</div>
          </div>
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
      <div class="row cat-container-button" align="center">
        <button
          class="button-to-go-car margin-top40"
          @click="$router.push('/catalog/')"
        >
          Перейти в каталог
        </button>
      </div>
      <div class="service">
        <h1>Сервис</h1>
        <p>
          Специализируемся только на марке Porsche. Показываем процесс работы в
          социальных сетях.<br />
          Отправляем фото/видео отчет клиенту. Честно говорим о том, какие
          работы нужны, а в каких нет необходимости.
        </p>
      </div>
      <Carousel
        ref="myCarousel"
        :settings="settings"
        :breakpoints="breakpoints"
        v-if="services.length"
      >
        <Slide v-for="slide in services" :key="slide.id">
          <div class="carousel__item" @click="setService(slide)">
            <span class="card-arrow"></span>
            <div class="card-box" v-lazy-load="'image'">
              <img
                class="card-image"
                :data-url="slide.image"
                :alt="slide.name"
                src="../assets/images/product/no_image.webp"
              />
            </div>
            <div class="title-item-slider">{{ slide.name }}</div>
          </div>
        </Slide>
        <template #addons="{ slidesCount }">
          <Navigation v-if="slidesCount >= 1" />
          <Pagination class="pagination-visible" />
        </template>
      </Carousel>
      <div class="row serv-container-button">
        <button
          class="button-to-go-car margin-top40"
          @click="$router.push('/services/')"
        >
          Выбрать услугу
        </button>
      </div>
    </div>
    <!-- .container end -->
    <div class="about">
      <div v-lazy-load="'image'" class="container about-white-bg">
        <div class="about-text">
          <h1>О нас</h1>
          <p>
            Компания Порша Ру специализируется на обслуживании и ремонте Порше.
            А также профессионально подбирает и продает запчасти на эту марку.
          </p>
          <p>
            Наша внутренняя политика основана на открытости по отношению к
            клиентам. Мы не навязываем услуги, и говорим открыто, что
            действительно нужно сделать по ремонту, а в чем нет необходимости,
            или это может подождать.
          </p>
          <p>
            Также, при работе с автомобилем мы смотрим на будущее, чтобы решить
            не только проблему здесь и сейчас, но и максимально предотвратить
            новые.
          </p>
          <div class="read-more">
            <router-link to="/about-us/" class="link-more-read">
              Читать дальше >
            </router-link>
          </div>
        </div>
        <img
          class="img-about"
          :data-url="'/img/about_img_man_1024.60c5eb23.webp'"
          alt="about"
        />
      </div>
    </div>
    <Suspense>
      <template #default>
        <YandexMapComp class="px-4" />
      </template>
      <template #fallback>
        <clip-loader :color="'#1e3246'" />
      </template>
    </Suspense>
  </div>
</template>
<script>
import ClipLoader from "vue-spinner/src/ClipLoader";
import { Carousel, Slide, Navigation, Pagination } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  name: "Home",
  components: {
    ClipLoader,
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  data() {
    return {
      cat: [],
      services: [],
      settings: {
        itemsToShow: 5,
        snapAlign: "start",
        itemsToScroll: 4,
        modelValue: 1,
      },
      breakpoints: {
        300: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 470px and up
        470: {
          itemsToShow: 2.5,
          snapAlign: "center",
        },
        // 700px and up
        700: {
          itemsToShow: 5,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 5,
          snapAlign: "start",
        },
        1440: {
          itemsToShow: 5,
          snapAlign: "start",
          // itemsToScroll: 7,
        },
      },
    };
  },
  created() {
    this.$store.dispatch("getCategories");
    this.$store.dispatch("getServiceTypes");
  },
  methods: {
    setCategory(id) {
      this.$store.dispatch("setCurrentCategory", id);
      this.$router.push({
        name: "Category",
        params: {
          category: "products",
        },
      });
    },
    setService(obj) {
      localStorage.setItem(
        "defService",
        JSON.stringify({ id: obj.id, name: obj.name })
      );
      this.$router.push({ name: "Services" });
    },
  },
  watch: {
    "$store.state.categories": {
      handler(categories) {
        this.cat = categories;
      },
    },
    "$store.state.serviceTypes": {
      handler(types) {
        this.services = types;
      },
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/assets/sass/styles.sass"
.carousel__item
  cursor: pointer
  transition: transform 0.25s ease-in-out
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15)
  border-radius: 4px
  padding-top: 15px
  margin: 20px 11px 40px
  width: 200px
  height: 228px
  text-align: center

  .card-arrow
    display: block
    margin-right: 15px
    height: 20px
    background-image: url("../assets/images/bange-arrow.svg")
    background-position: right bottom
    background-repeat: no-repeat

  .card-box
    display: flex
    height: 130px
    max-width: 160px
    width: 100%
    margin: 0 auto

    .card-image
      padding: 10px 0
      width: 100%
      margin: auto
      height: 100% !important

  .title-item-slider
    padding: 5px 15px 0
    font-size: $main-font-size
    font-weight: 400
    line-height: 19px
    color: $main-color

#home
  margin: 115px 0

  .catalog
    text-align: center
    margin-bottom: 20px



  .catalog
    h1
      font-size: 36px
      line-height: 45px
    p
      font-size: 18px
      line-height: 27px
  .service
    h1
      line-height: 45px
    p
      font-size: 18px

  .about
    margin-top: 120px
    background: linear-gradient(to right, #1e3246 50%, #e8ebec 50%)
    width: 100%
    height: 515px

    .about-white-bg
      padding: 50px 0
      display: flex
      background-repeat: no-repeat
      background-size: cover
      height: 100%

      .about-text
        height: 413px
        width: 603px

        p, h1, .link-more-read
          color: #fff !important

        p
          font-size: 18px
          font-weight: 400
          line-height: 27px

        h1
          font-size: 36px
          font-weight: 700
          margin-bottom: 8px

        .link-more-read
          font-weight: 700
          font-size: 18px
          line-height: 21px
          &:hover
            text-decoration: underline !important

      .img-about
        width: auto
        height: 415px !important
        margin-left: auto

.cat-container-button,
.serv-container-button
  justify-content: center
  text-align: center

@media (max-width: 460px)
  .cat-container-button,
  .serv-container-button
    padding: 0 15px
    .button-to-go-car
      width: 100%
      &:hover
        background-color: #fff

  #home
    margin: 60px 0 50px

    .catalog,
    .service
      p
        font-size: 14px
        line-height: 20px

    .container
      h1
        font-size: 22px
        line-height: 110%
        text-align: start
    .about
      margin-top: 80px
      height: fit-content

      .about-white-bg
        padding-bottom: 20px
        min-height: 800px
        flex-wrap: wrap
        background-position: bottom
        background-image: url("../assets/images/bg/about_bg_320.webp")

        .about-text
          padding: 0 15px
          height: 370px

          h1
            font-size: 22px
            line-height: 24px
            margin-bottom: 8px

          p
            font-size: 14px
            line-height: 19px

          .link-more-read
            font-size: 14px
            line-height: 40px

        .img-about
          height: auto !important
          padding: 0 15px
          margin-bottom: 25px

@media (min-width: 992px) and (max-width: 1439px)
  .carousel__item
    height: 170px
    width: 146px
    margin: 10px 7px 20px

    .card-arrow
      height: 14px
      background-size: contain

    .card-box
      padding: 10px 0 0
      height: 80px

      .card-image
        padding: 0
        width: 104px

    .title-item-slider
      padding-top: 10px
      font-size: 12px
      line-height: 15px
  #home
    margin-top: 90px

    .catalog,
    .service
      h1
        font-size: 32px
      p
        font-size: 14px
        line-height: 150%

    .about
      margin-top: 90px
      height: 452px

      .about-white-bg
        padding-top: 50px
        background-image: url("../assets/images/bg/about_bg_1024.webp")

        .about-text
          height: 370px
          padding-right: 100px

          p
            font-size: 14px
            line-height: 150%
            margin-bottom: 25px

          h1
            font-size: 32px
            line-height: 125%

          .read-more
            margin-top: 50px

            .link-more-read
              font-size: 14px

        .img-about
          height: 352px !important
          width: 417px

@media (min-width: 1440px) and (max-width: 1919px)
  .about
    .about-white-bg
      background-image: url("../assets/images/bg/about_bg_1440.webp")
      width: 1440px

@media screen and (min-width: 1920px)
  .about
    .about-white-bg
      background-image: url("../assets/images/bg/about_bg_1920.webp")
      width: 1920px
</style>
