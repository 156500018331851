<template>
  <body
    class="body-startup body-header-transparent main-page"
    :class="{ 'nav-mobile-open': isOpen, 'modal-open': isCartOpen }"
  >
    <div class="shadow" v-show="isCartOpen"></div>
    <NavMobile v-if="isMobile" :closeBurger="BurgerClose" />
    <CartSideBar v-if="isCartOpen" :cartOpen="CartOpen" />
    <AppHeader :openBurger="BurgerOpen" :cartOpen="CartOpen" />
    <div id="body-wrap">
      <router-view />
    </div>
    <AppFooter />
  </body>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppFooter from "@/components/AppFooter";
import { defineAsyncComponent } from "vue";

export default {
  name: "App",
  components: {
    NavMobile: defineAsyncComponent(() => import("@/components/NavMobile")),
    AppHeader,
    AppFooter,
    CartSideBar: defineAsyncComponent(() => import("@/components/CartSideBar")),
  },
  computed: {
    isMobile() {
      return this.winWidth < 461;
    },
  },
  created() {
    this.winWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.winWidth = window.innerWidth;
    });
  },
  mounted() {
    this.$store.commit("updateCartLocalStorage");
  },
  unmounted() {
    window.removeEventListener("resize", () => {
      this.winWidth = window.innerWidth;
    });
  },
  data() {
    return {
      isOpen: false,
      isCartOpen: false,
      winWidth: null,
    };
  },
  methods: {
    BurgerOpen: function () {
      this.isOpen = !this.isOpen;
    },
    BurgerClose: function () {
      this.isOpen = !this.isOpen;
    },
    CartOpen: function () {
      this.isCartOpen = !this.isCartOpen;
      document.querySelector("body").style.overflow = this.isCartOpen
        ? "hidden"
        : "";
    },
  },
};
</script>

<style>
.body-startup {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}

#body-wrap {
  width: 100%;
}

.shadow {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  z-index: 8000;
}
</style>
