<template>
  <div id="category-list">
    <div class="container-breadcrumbs">
      <div class="breadcrumbs">
        <router-link to="/">Главная</router-link>
        /
        <router-link to="/catalog/">Каталог</router-link>
        /
        <span class="current">{{ breadCrumbs() }}</span>
      </div>
    </div>
    <div class="category-box">
      <CustomSelect
        :options="[]"
        :default="{ name: 'Настройка фильтров' }"
        class="select filter-btn"
        @click="showFilter"
      />
      <div class="filter" ref="filter">
        <div class="category-checkbox">
          <div class="sidebar-widget">
            <div class="category-title">Категория</div>
            <div class="sidebar-widget-list">
              <ul>
                <li>
                  <div
                    class="sidebar-widget-list-left"
                    :class="{
                      checked: checkboxValue.length === categories.length,
                    }"
                  >
                    <input
                      :id="0"
                      type="checkbox"
                      @change="changeAllCategories()"
                      :value="0"
                      v-model="allCategories"
                    />
                    <label :for="0">Все категории</label>
                  </div>
                </li>
                <li v-for="(option, index) in categories" :key="index">
                  <div
                    class="sidebar-widget-list-left"
                    :class="{ checked: checkboxValue.includes(option.id) }"
                  >
                    <input
                      :id="option.id"
                      type="checkbox"
                      @change="resetPriceRange()"
                      :value="option.id"
                      v-model="checkboxValue"
                    />
                    <label :for="option.id">{{ option.name }}</label>
                  </div>
                </li>
              </ul>
            </div>
            <clip-loader
              :color="'#1e3246'"
              :loading="isLoadingCategory"
            ></clip-loader>
          </div>
        </div>
        <label class="slider-label" for="rangeSlider">Цена</label>
        <VueSlider
          class="slider-range"
          ref="rangeSlider"
          :key="options.min + options.max"
          v-bind="options"
          v-model="priceRange"
          :dot-attrs="{ 'aria-label': 'Цена' }"
          @change="changeRange($event)"
        />
      </div>
      <div class="product-box">
        <div class="product-sorting">
          <span class="title-select">
            Показано {{ show_item }} из {{ info.count }}
          </span>
          <CustomSelect
            :options="sortOptions"
            class="select"
            @input="sorted"
            :default="sortSelected"
            :key="sortSelected"
          />
          <Paginate
            v-if="info_result.length"
            :page-count="info.total_pages"
            :page-range="2"
            :click-handler="loadNext"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            v-model="page"
          >
          </Paginate>
        </div>
        <div class="product-container">
          <ProductsList
            v-for="(product, index) in info_result"
            :key="index"
            :product="product"
          />
        </div>
        <div class="product-sorting w-100" v-if="info_result.length">
          <span class="title-select"
            >Показано {{ show_item }} из {{ info.count }}</span
          >
          <CustomSelect
            :options="sortOptions"
            class="select"
            @input="sorted"
            :default="sortSelected"
            :key="sortSelected"
          />
          <Paginate
            :page-count="info.total_pages"
            :page-range="2"
            :click-handler="loadNext"
            :prev-text="'<'"
            :next-text="'>'"
            :container-class="'pagination'"
            v-model="page"
          >
          </Paginate>
        </div>
      </div>
      <clip-loader :color="'#1e3246'" :loading="isLoadingProducts" />
      <YandexMapView />
    </div>
  </div>
</template>

<script>
import ProductsList from "@/components/products/ProductsList";
import Paginate from "vuejs-paginate-next";
import YandexMapView from "@/components/YandexMap";
import ClipLoader from "vue-spinner/src/ClipLoader";
import CustomSelect from "@/components/CustomSelect";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  name: "Category",
  components: {
    ClipLoader,
    YandexMapView,
    ProductsList,
    Paginate,
    CustomSelect,
    VueSlider,
  },
  data() {
    return {
      info: {
        next: null,
        previous: null,
        count: 0,
        total_pages: 0,
        results: [],
      },
      sortOptions: [
        { name: "Все" },
        { name: "Новые", type: "asc" },
        { name: "Старые", type: "desc" },
      ],
      sortSelected: { name: "Все" },
      info_result: [],
      checkboxValue: [],
      categories: [],
      allCategories: true,
      current_cat: null,
      page: 1,
      isLoadingCategory: true,
      isLoadingProducts: true,
      breadcrumbs: "Все товары",
      priceRange: [],
      options: {
        dotSize: 24,
        width: 200,
        height: 2,
        contained: false,
        direction: "ltr",
        data: null,
        dataLabel: "label",
        dataValue: "priceRange",
        min: null,
        max: null,
        interval: 1,
        disabled: false,
        clickable: true,
        duration: 0.5,
        adsorb: true,
        lazy: true,
        tooltip: "active",
        tooltipPlacement: "top",
        tooltipFormatter: void 0,
        useKeyboard: true,
        keydownHook: null,
        dragOnClick: true,
        enableCross: true,
        fixed: false,
        minRange: 500,
        maxRange: 42000000,
        order: true,
        marks: [],
        dotOptions: void 0,
        dotAttrs: void 0,
        process: true,
        dotStyle: {
          backgroundColor: "#1e3246",
          borderColor: "#1e3246",
        },
        railStyle: {
          backgroundColor: "#BDBDBD",
        },
        processStyle: {
          backgroundColor: "#1e3246",
        },
        tooltipStyle: void 0,
        stepStyle: {
          display: "none",
        },
        stepActiveStyle: void 0,
        labelStyle: void 0,
        labelActiveStyle: void 0,
      },
    };
  },
  computed: {
    show_item() {
      return this.info_result.length;
    },
    search() {
      const URLParams = new URLSearchParams();
      if (!this.$store.getters.querySearch.length) return "";
      URLParams.append("search", this.$store.getters.querySearch);
      return `&${URLParams}`;
    },
  },
  methods: {
    sorted(val) {
      if (this.sortSelected.name !== val.name) {
        this.sortSelected = val;
        this.getListProducts(this.priceRange);
      }
    },
    changeAllCategories() {
      this.allCategories = !this.allCategories;
      this.checkboxValue =
        this.checkboxValue.length === this.categories.length
          ? []
          : this.categories.map((el) => el.id);
      this.priceRange = this.checkboxValue.length
        ? [this.options.min, this.options.max]
        : this.priceRange;
    },
    resetPriceRange() {
      setTimeout(() => {
        this.priceRange = [this.options.min, this.options.max];
      }, 100);
    },
    breadCrumbs() {
      const route_name = this.$route.params.category;
      if (route_name === "products") {
        return this.breadcrumbs;
      } else {
        const tt = this.categories.find((item) => item.slug === route_name);
        if (tt) {
          const obj_to_str = JSON.stringify(tt);
          const parseDate = JSON.parse(obj_to_str);
          this.breadcrumbs = parseDate["name"];
        }
        return this.breadcrumbs;
      }
    },
    loadNext() {
      this.getListProducts(this.priceRange);
      const select = document.querySelector(".select");

      if (select) {
        select.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    filteredProducts() {
      this.info_result = this.info.results;
    },
    getListProducts(priceRange = []) {
      if (!this.checkboxValue.length) {
        this.info_result = [];
        this.info = {
          next: null,
          previous: null,
          count: 0,
          total_pages: 0,
          results: [],
        };
      }
      let category = !this.allCategories
        ? `page=${this.page}&category=${this.checkboxValue.join(",")}`
        : `page=${this.page}`;
      const costRange = priceRange.length
        ? `&price_max=${priceRange[1]}&price_min=${priceRange[0]}`
        : "";
      const sorted =
        this.sortSelected.name !== "Все"
          ? `&sorted_pr=${this.sortSelected.type}`
          : "";
      this.$store.dispatch(
        "getProducts",
        `${category}${costRange}${sorted}${this.search}`
      );
    },
    getCategory() {
      this.$store.dispatch("getCategories");
    },
    changeRange(params) {
      this.page = 1;
      this.getListProducts(params);
    },
    showFilter() {
      const filter = this.$refs.filter;
      if (filter.classList.contains("d-flex")) {
        filter.classList.remove("d-flex");
      } else {
        filter.classList.add("d-flex");
      }
    },
  },
  created() {
    this.getListProducts();
    this.getCategory();
    this.breadCrumbs();
  },
  unmounted() {
    this.$store.dispatch("setQuerySearch", "");
    this.$store.dispatch("setCurrentCategory", null);
  },
  watch: {
    checkboxValue(val) {
      this.page = 1;
      this.allCategories = val.length === this.categories.length;
      this.getListProducts();
    },
    "options.min": {
      handler(min) {
        this.priceRange[0] = min;
      },
    },
    "options.max": {
      handler(max) {
        this.priceRange[1] = max;
      },
    },
    "$store.state.products": {
      handler(products) {
        this.info = products;
        if (products.count > 0) {
          this.options.min = products.min_price;
          this.options.max = products.max_price;
          this.options.marks = [products.min_price, products.max_price];
        }
        this.isLoadingProducts = false;
        this.filteredProducts();
      },
      deep: true,
    },
    "$store.state.querySearch": {
      handler() {
        this.page = 1;
        this.getListProducts();
      },
    },
    "$store.state.categories": {
      handler(categories) {
        this.categories = categories;
        this.checkboxValue = this.$store.state.currentCategoryId
          ? [this.$store.state.currentCategoryId]
          : categories.map((el) => el.id);
        this.isLoadingCategory = false;
      },
    },
  },
};
</script>

<style scoped lang="sass">
@import "@/assets/sass/styles"
#category-list
  display: flex
  flex-wrap: wrap
  justify-content: center

  .category-box
    display: flex
    flex-wrap: wrap

    .filter-btn
      display: none

    .filter
      display: flex
      flex-wrap: wrap
      width: 304px
      height: 630px

      .slider-label
        height: 20px
        font-weight: 600
        font-size: 18px
        line-height: 150%
        align-self: flex-end
        margin-bottom: 21px

      .slider-range
        margin-left: 10px
        width: 284px !important

    .product-box
      width: 1016px
      float: left
      margin-top: 15px

      .product-container
        display: flex
        flex-wrap: wrap
        margin-bottom: 25px
        min-height: 345px

      .product-sorting
        font-weight: 400
        line-height: 19px
        color: #1E3246
        margin-bottom: 25px
        display: flex
        align-items: center
        font-size: $main-font-size

        .title-select
          margin: 0 30px
          min-width: 182px
          height: 100%
          line-height: 2

  .sidebar-widget
    .sidebar-widget-list
      ul
        list-style: none
        padding-left: 0
      li,
      label
        font-size: $main-font-size

        .sidebar-widget-list-left
          display: flex
          align-items: center
          &.checked
            label
              color: $main-color

              &:hover
                &:before
                  border-color: $main-color

              &:before
                margin: 6px 12px 6px 0
                height: 24px
                width: 24px
                border-color: $main-color
                background: $main-color url("../../assets/images/check.svg") no-repeat center
                background-size: contain

          label
            margin-bottom: 0
            font-size: $main-font-size
            cursor: pointer
            color: #858585

            &:hover
              color: #5F6C79

              &:before
                border-color: #5F6C79

            &:before
              content: " "
              display: inline-block
              position: relative
              width: 18px
              height: 18px
              border: 2px solid $default-color
              margin: 9px 15px 9px 3px
              border-radius: 2px
              vertical-align: middle

          input[type="checkbox"]
            display: none

@media (max-width: 460px)
  #category-list
    padding: 0 15px
    margin: 80px 0 50px

    .category-box
      width: 100%

      .filter-btn
        display: block
        width: calc(100% - 30px)
        height: 40px
        position: absolute
        top: 130px


      .filter
        position: absolute
        display: none
        height: auto
        width: calc(100% - 30px)
        top: 175px
        z-index: 10
        background-color: #fff
        left: 0
        padding: 15px
        margin: 0 15px
        border: 1px solid $main-color
        border-radius: $border-radius

        .category-checkbox
          margin: 0
          width: 100%

        .slider-range
          width: 100% !important
          margin: 20px 30px

      .product-box
        width: 100%
        display: flex
        flex-direction: column
        margin-top: 110px

        .product-container
          flex-direction: column

        .product-sorting
          flex-wrap: wrap
          justify-content: center

          .pagination
            margin: 10px auto 0

          .title-select
            height: inherit
            margin: 0
            padding: 0
            font-size: 13px
            line-height: 16px
            min-width: auto
            width: 100%
            text-align: center

          .select
            display: none

        .product-sorting:not(.w-100)
          .select
            display: block
            width: calc(100% - 30px)
            height: 40px
            position: absolute
            top: 80px
            z-index: 10

    .sidebar-widget
      .category-title
        font-size: 14px

      .sidebar-widget-list
        li
          .sidebar-widget-list-left
            label
              font-size: 14px
              &:before
                margin: 9px 10px 11px 2px
                width: 14px
                height: 14px

            &.checked
              label
                &:before
                  margin: 7px 8px 9px 0
                  width: 18px
                  height: 18px

@media (max-width: 1439px) and (min-width: 992px)
  #category-list
    .category-box
      .filter
        width: 227px
        height: 570px

        .slider-label
          font-size: $main-font-size

        .slider-range
          width: 200px !important

      .product-box
        width: 731px
        margin-top: 6px
        margin-bottom: 146px

        .product-container
          min-height: 323px

        .product-sorting
          margin-bottom: 15px

          .title-select
            margin: 0 16px
            font-size: 14px
            line-height: 17px

    .sidebar-widget
      .category-title
        font-size: 16px

      .sidebar-widget-list
        li
          .sidebar-widget-list-left
            label
              font-size: 14px

              &:before
                margin: 7px 13px 11px 1px
                width: 18px
                height: 18px

            &.checked
              label
                &:before
                  margin: 6px 12px 10px 0
                  height: 20px
                  width: 20px

@media (min-width: 1920px)
  #category-list
    display: grid
</style>
